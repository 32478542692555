import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import singleClick from './images/single-tap.svg';
import pinch from './images/zoom.svg';
import rightClick from './images/right-click.svg';
import doubleClick from './images/double-tap.svg';

// Map nvaigation widget
// I started doing this as a Mapbox control but realised that Mapbox Controls are incompatible
// with Reaact Nodes, so I decided to do this as a standalone element
export default function MapNavigation() {
  return (
    <aside className="map-control">
      <Accordion>
        <AccordionSummary aria-controls="map-navigation">
          <h4 className="map-control-title">Map controls</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <img src={singleClick} alt="left click or single tap" width="100" />
            <p>Pan</p>
            <img src={pinch} alt="scroll or two-finger pinch" width="100" />
            <p>Zoom</p>
            <img
              src={rightClick}
              alt="right click or two-finger drag"
              width="100"
            />
            <p>Tilt / Orbit</p>
            <img src={doubleClick} alt="double click or tap" width="100" />
            <p>Interact</p>
          </div>
        </AccordionDetails>
      </Accordion>
    </aside>
  );
}
