import * as papa from 'papaparse';
import * as turf from '@turf/turf';

const NUMBINSWIDTH = 6;
const NUMBINSHEIGHT = 6;
const TOTALNUMJENKSBINS = 12;
export const MINWIDTH = 0.01;
export const MINHEIGHT = 500;
export const MAXWIDTH = 0;
export const MAXHEIGHT = 10000;
export let JENKSARR = [];
let DATAS = { bars: {}, areas: {} };
export let HEIGHTSTEPS = [-1];
export let RADIUSSTEPS = [-1];
export let currParameters = []
let histogramData = {}


export function getHighestValue(data, parameter) {

  let highest = -1;
  for (let i = 0; i < data.features.length; i++) {
    const val = parseFloat(data.features[i].properties['indices'][parameter]);
    if (isNaN(val) || val === -1) continue;
    if (val > highest) highest = val;
  }
  return highest;
}

//This is bad and won't work with new datasets. Was a requirement for AG's presentation with not enough time. If initial values are supposed to be
// a core functionality proper config files (i.e. when pulling from the database) have to be implemented.
export function getConfig(key) {
  switch (key) {

    case "Liveability Indicators":
      return {
        rCol: 'transformed_liveability_index',
        col: 'working_long_hours',
        biv1: 'working_long_hours',
        biv2: 'normalised_net_disposable_income',
        height: 'normalised_dwelling_density',
        width: 'normalised_transport_accessibility_index',
        rBiv1: 'transformed_liveability_index',
        rBiv2: 'normalised_net_disposable_income'
      }
    case "Diversity Indices":
    case "Diversity Indices and Liveability Indicators":
      return {
        width: 'Income Diversity (Simpson’s Index)',
        height: 'Population Size',
        col: 'Ethnic Diversity per capita',
        biv1: 'Ethnic Diversity per capita',
        biv2: 'Residential Turnover (% arrived in past 5 years)',
        rCol: 'Residential Turnover (% arrived in past 5 years)',
        rBiv1: 'Residential Turnover (% arrived in past 5 years)',
        rBiv2: 'Ethnic Diversity per capita'
      }
    default:
      console.log("invalid config key: " + key)
  }
}
export function reset() {
  DATAS = null;
  JENKSARR = null;
  currParameters = null;
  histogramData = null;
}

export function setIndices(indices) {
  // console.log("setIndices")
  currParameters = indices
}
export function setJenksArr(jenksarr) {
  //console.log("setJenks")
  JENKSARR = jenksarr
  if (DATAS && JENKSARR.length > 0) {
    histogramData = getHistogramData(currParameters,
      DATAS.bars)
  }
}
export function setData(data) {
  //console.log("setData")
  //console.log(data)
  //reset();
  DATAS = data;

  if (DATAS && JENKSARR.length > 0) {
    histogramData = getHistogramData(currParameters,
      DATAS.bars)
  }
}
export function getDatas() {
  return DATAS;
}

export function expHistogramData() {
  return histogramData;
}
function makeHEIGHT_WIDTHJenks(param, newmin, newmax, isHeight) {
  console.log("height " + param)
  console.log(JENKSARR["all"])
  let paramjenksarr = JENKSARR["all"][param][TOTALNUMJENKSBINS];
 
  let smallerArr = []
  if (paramjenksarr) {
    let orimax = paramjenksarr[paramjenksarr.length - 1];
    let orimin = paramjenksarr[0];

    for (let i = 0; i < paramjenksarr.length; i += TOTALNUMJENKSBINS / NUMBINSWIDTH) {
      smallerArr.push(paramjenksarr[i]);
    }

    for (let i = 0; i < smallerArr.length; i++) {
      smallerArr[i] = isHeight ? Math.floor(getNewRangeValue(smallerArr[i], orimin, orimax, newmin, newmax)) : getNewRangeValue(smallerArr[i], orimin, orimax, newmin, newmax);
    }
    //smaller Arr now has 7 entries, but we need 6 different sizes. create the final array by calculating the middle between 2 values.
    let finalArr = []
    for (let i = 0; i < smallerArr.length - 1; i++) {
      finalArr.push(smallerArr[i] + (smallerArr[i + 1] - smallerArr[i]) / 2)
    }
    if (isHeight) {
      HEIGHTSTEPS = finalArr;
    } else {
      RADIUSSTEPS = finalArr;
    }
  } else {
    console.log("No Jenks available for " + param)
  }
  // console.log(smallerArr)
}

export function getLowestValue(data, parameter) {
  //Toan:
  //  barHeight = Math.max(value * currData.json.scale[col] * feature.scale, 10)

  var lowest = Math.MAX_VALUE;
  for (let i = 0; i < data.features.length; i++) {
    const val = parseFloat(data.features[i].properties['indices'][parameter]);
    if (isNaN(val)) {
      continue;
    }
    if (val === -1) continue;
    if (val < lowest) lowest = val;
  }

  return lowest;
}

const indexConverter = {
  'Total-pop': 'Population Size',
  'Population': 'Population Size',
  'Ethnicity-raw-count': 'Ethnic Diversity (# of ethnicities)',
  'Ethnicity-count': 'Ethnic Diversity (# of ethnicities)',
  'Ethnicity-raw-normalized': 'Ethnic Diversity per capita',
  'Ethnicity-raw-norm': 'Ethnic Diversity per capita',
  'Ethnicity-norm': 'Ethnic Diversity per capita',
  'Mobility-pct': 'Residential Turnover (% arrived in past 5 years)',
  'Mobility-raw-pct': 'Residential Turnover (% arrived in past 5 years)',
  'Generation-SI': 'Arrival-wave Diversity (Simpson’s Index)',
  'Generation-raw-SI': 'Arrival-wave Diversity (Simpson’s Index)',
  'Income-SI': 'Income Diversity (Simpson’s Index)',
  'Income-raw-SI': 'Income Diversity (Simpson’s Index)',
  'Education-SI': 'Educational Diversity (Simpson’s Index)',
  'Education-raw-SI': 'Educational Diversity (Simpson’s Index)',
  'Immig-category-raw-SI': 'Legal Status Diversity (Simpson’s Index)',
  'urban_liveability_index': 'Urban Liveability Index',
  'ier_score': 'Index of Economic Resources (IER) Score',
  'irsad_score': 'Index of Relative Socio-economic Advantage and Disadvantage (IRSAD) Score',
  'ieo_score': 'Index of Education and Occupation (IEO) Score',
  'M_DNGTS_Tot': 'Did not go to school total (Male)',
  'F_DNGTS_Tot': 'Did not go to school total (Female)',
  'P_DNGTS_Tot': 'Did not go to school total (Persons)',
  'F_Unem_look_Tot_Tot': 'Unemployed looking for work (Female)',
  'M_Unem_look_Tot_Tot': 'Unemployed looking for work (Male)',
  'P_Unem_look_Tot_Tot': 'Unemployed looking for work (Persons)',
  'M_Emp_FullT_Tot': 'Employed full-time (Male)',
  'F_Emp_FullT_Tot': 'Employed full-time (Female)',
  'P_Emp_FullT_Tot': 'Employed full-time (Persons)',
  'M_Emp_PartT_Tot': 'Employed part-time (Male',
  'F_Emp_PartT_Tot': 'Employed part-time (Female)',
  'P_Emp_PartT_Tot': 'Employed part-time (Persons)',
  'Tot_Family_households': 'Number of family households',
  'IC_not_stated_Total': 'Internet connection not stated',
  'p_tot_need_for_assistance': 'Persons total need for assistance',
  'p_tot_prvided_unpaid_assist': 'Persons total provided unpaid assistance',
  'p_tot_no_unpaid_asst_prvided': 'Persons total no unpaid assistance provided',
};

export function getFinalIndexName(original) {
  if (original === 'all') {
    return indexConverter.getKeys();
  } else {
    if (indexConverter[original.trim()]) {
      return indexConverter[original.trim()];
    } else {
      return (original.trim())
    }
  }
}

export function getColors(num) {
  switch (num) {
    case 3:
      return ['#a1d99b', '#41ab5d', '#006d2c'];

    case 6:
      return ['#a1d99b', '#74c476', '#41ab5d', '#238b45', '#006d2c', '#00441b'];

    default:
      console.error(
        'invalid number for colours: ' + num + ',  defaulting to 3.',
      );
      return ['#a1d99b', '#41ab5d', '#006d2c'];
  }
}

export function getColors2(num) {
  switch (num) {
    case 3:
      return ['#fdae6b', '#f16913', '#a63603'];

    case 6:
      return ['#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#a63603', '#7f2704'];

    default:
      console.error(
        'invalid number for colours: ' + num + ',  defaulting to 3.',
      );
      return ['#fdae6b', '#f16913', '#a63603'];
  }
}
export const bivariateColors2 = {
  '00': '#e8e8e8',
  '01': '#b0d5df',
  '02': '#64acbe',
  '10': '#e4acac',
  '11': '#ad9ea5',
  '12': '#627f8c',
  '20': '#c85a5a',
  '21': '#985356',
  '22': '#574249',
};

export const RGBToHex = (rgb) => {
  let r = parseInt(rgb[0]).toString(16);
  let g = parseInt(rgb[1]).toString(16);
  let b = parseInt(rgb[2]).toString(16);

  if (r.length === 1) r = '0' + r;
  if (g.length === 1) g = '0' + g;
  if (b.length === 1) b = '0' + b;

  return '#' + r + g + b;
};
export const bivariateColors = {
  '00': '#e8e8e8',
  '01': '#dfb0d6',
  '02': '#be64ac',
  '10': '#ace4e4',
  '11': '#a5add3',
  '12': '#8c62aa',
  '20': '#5ac8c8',
  '21': '#5698b9',
  '22': '#3b4994',
};

export const verticalKeysBiv = [
  '00',
  '01',
  '02',
  '10',
  '11',
  '12',
  '20',
  '21',
  '22',
];
export const horizontalKeysBiv = [
  '20',
  '10',
  '00',
  '21',
  '11',
  '01',
  '22',
  '12',
  '02',
];

export function getUIDForCityYear(city, year) {
  year = Number(year); // TODO: Find out what's converting year to string type
  if (city === 'Vancouver') {
    return 'CSDUID';
  } else if (city === 'Melbourne' || city === 'Sydney') {
    if (year === 2016) {
      return 'SA1_MAIN16';
    } else if (year === 2011) {
      return 'SA1_MAIN11';
    } else if (year === 2006) {
      return 'SLA_MAINCO';
    } else if (year === 2001) {
      return 'CD_Code';
    }
  } else if (city === 'Auckland') {
    return 'Meshblock';
  }
  return null;
}
export async function parseCsv(file) {
  return new Promise((resolve, reject) => {
    papa.parse(file, {
      download: true,
      complete: function (results) {
        return resolve(results);
      },
      error: function (error) {
        console.log(error);
        return reject(error);
      },
    });
  });
}

export function getAreaIDForCityYear(city, year) {
  year = Number(year);
  //console.log('getAreaIDForCityYear', city, year, typeof year);
  if (city === 'Vancouver') {
    return 'CSDNAME';
  } else if (city === 'Melbourne' || city === 'Sydney') {
    if (year === 2016) {
      return 'SA2_NAME16';
    } else if (year === 2011) {
      return 'SA2_NAME11';
    } else if (year === 2006) {
      return 'SLA_NAME_2';
    }
  } else if (city === 'Auckland') {
    return 'AreaUnitNa';
  }
  return null;
}

export function getIndexTooltips(column) {
  let tooltip = '';
  if (column.includes('Population Size')) {
    tooltip = 'Count of individuals';
  } else if (column.includes('Ethnic Diversity')) {
    tooltip = 'The number of ethnic origins divided by the population';
  } else if (column.includes('Residential Turnover')) {
    tooltip =
      'The number of people living there who were not residing there 5 years ago as a percentage of the population';
  } else if (column.includes('Arrival-wave Diversity')) {
    tooltip = `Simpson's index of entropy, calculated on the basis of decades`;
  } else if (column.includes('Income Diversity')) {
    tooltip = `Simpson's Index of entropy, calculated on the basis of 11 income categories that range from $0-5,000, to $100,000/year and more`;
  } else if (column.includes('Educational Diversity')) {
    tooltip = `Simpson's Index of entropy, calculated on the basis of 4 educational attainment groups (no high school qualification; high school diploma; more than high school; completed university degree)`;
  } else if (column.includes('Legal Status Diversity')) {
    tooltip = `Simpson's Index of entropy, calculated on the basis of 3 groups (economic immigrants; family immigrants; refugees)`;
  } else if (column === 'null') {
    tooltip = 'This attribute is not in use.';
  } else {
    tooltip = 'No tooltip is available';
  }
  return tooltip;
}


export function getNewRangeValue(x, oldMin, oldMax, newMin, newMax) {
  //console.log(x)
  if (oldMin > oldMax) {
    var a = oldMin;
    oldMin = oldMax;
    oldMax = a;
  }

  if (newMin > newMax) {
    var b = newMin;
    newMin = newMax;
    newMax = b;
  }
  if (x < 0 || isNaN(x) || x === undefined) return -1;

  if (oldMin === -1) {
    oldMin = oldMin + 1;
    oldMax = oldMax + 1;
    x = x + 1;
  }
  var portion = ((x - oldMin) * (newMax - newMin)) / (oldMax - oldMin);
  var result = portion + newMin;

  if (isNaN(result) || result === undefined) {
    return -1;
  }
  return result;
}

export function getValuesByParameter(param, features) {
  let returnarray = [];
  // console.log(features)
  for (let i = 0; i < features.length; i++) {
    if (
      features[i]['properties'][param] !== undefined &&
      features[i]['properties'][param] !== -1 &&
      !isNaN(features[i]['properties'][param])
    )
      returnarray.push(features[i]['properties'][param]);
  }
  return returnarray;
}
export function getNoValueColor() {
  return '#9e9e9e';
}

export const neighborhoodHistogramColor = 'grey';
export function getJenksIndex(arr, val) {
  if (arr === undefined) {
    return 0;
  }
  for (let i = 1; i < arr.length; i++) {
    if (val <= arr[i]) {
      // console.log(i)
      return i - 1;
    }
  }
  return -1;
}

export function getComparatorsByCity(city) {
  switch (city) {
    case 'Melbourne':
      return {
        // 2001: {"sa1": "SA1_MAIN11" , "lga": "SA3_NAME11" },
        2006: { sa1: 'CD', SA3: 'LGA_NAME_2' },
        2011: { sa1: 'SA1_MAIN11', SA3: 'SA3_NAME11' },
        2016: { sa1: 'SA1_MAIN16', SA3: 'SA3_NAME16' },
      };
    case 'Sydney':
      return {
        2006: { sa1: 'CD_CODE_20', SA3: 'LGA_NAME_2' },
        2011: { sa1: 'SA1_MAIN11', SA3: 'SA3_NAME11' },
        2016: { sa1: 'SA1_MAIN16', SA3: 'SA3_NAME16' },
      };
    case 'Adelaide':
    case 'Perth':
    case 'Brisbane':
      return {
        2016: { sa1: 'SA1_MAIN16', SA3: 'SA3_NAME16' },
      };
    case 'Vancouver':
      return {
        2016: { sa1: 'DAUID', SA3: 'CCSNAME' },
      };
    case 'Auckland':
      return {
        1996: { sa1: 'ID', SA3: 'WardName' },
        2013: { sa1: 'ID', SA3: 'WardName' },
      };
    default:
      console.log('No lookups for that city yet: ' + city);
      return null;
  }
}

export const initialConfig = {
  width: 'Income Diversity (Simpson’s Index)',
  height: 'Population Size',
  color: 'Ethnic Diversity per capita',
  biv1: 'Ethnic Diversity per capita',
  biv2: 'Residential Turnover (% arrived in past 5 years)',
  areacolor: 'Residential Turnover (% arrived in past 5 years)',
  year: 2016,
  city: 'Melbourne',
  loadUrl: '/data_one/Melbourne_all-2016.json',
  postcodeUrl: "/data/PostcodeLookups/POA_2011_AUST.csv",
  indices: [],
  tag: "Diversity Indices and Liveability Indicators"
};

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// Assumes only low-med-high for use
export function getIndexText(index) {
  if (index === 0) return 'Low';
  else if (index === 1) return 'Average';
  else if (index === 2) return 'High';
  else return 'Unknown';
}

// Format the popup number to be human-friendly
export const renderNumber = (index, value) => {
  if (index === 'Population Size') {
    return `${value}`;
  } else {
    if (value >= 1) {
      return `${Math.round(value)}%`;
    } else {
      return `${Math.round(value * 100)}%`;
    }

    //} else if (index === 'Ethnic Diversity') {
    // } else {
    // 
  }
};

//this makes the linear histogram for the monovariate colour palette. It uses the colours in the dataset, rather than the parameter name.
export const getLinearHistograms = (
  isRegion,
  param
) => {

  //let numSubClasses = 12 / numColors; //3 colours in the legend will result in 4 sub"bins", 6 colours will have 2 bins each.
  let histData = [];

  for (let i = 0; i < TOTALNUMJENKSBINS; i++) {
    let objV = { bin: i };
    histData.push(objV);
  }
  //console.log(deepCopy(histData))
  let property;

  if (isRegion) {
    property = 'areaColor';
  } else {
    property = 'color';
  }

  if (JENKSARR['all'][param]) {
    //console.log(JENKSARR['all'][param])
    for (let i = 0; i < DATAS.bars["features"].length; i++) {
      let col;
      if (isRegion) {
        col = DATAS.areas["features"][i]['properties'][property];
      } else {
        col = DATAS.bars["features"][i]['properties'][property];
      }

      let val = getJenksIndex(
        JENKSARR["all"][param][TOTALNUMJENKSBINS],
        DATAS.bars["features"][i].properties['indices'][param],
      );

      if (val !== -1) {
        //get the parent bin, combined of the main bin plus the determined value which should be between 0 and 2
        if (histData[val][col]) {
          if (DATAS.bars["features"][i]['properties']['indices'][param] !== -1)
            histData[val][col] +=
              DATAS.bars["features"][i]['properties']['indices'][param];
        } else {
          if (DATAS.bars["features"][i]['properties']['indices'][param] !== -1)
            histData[val][col] =
              DATAS.bars["features"][i]['properties']['indices'][param];
        }
      }

    }
  } else {
    histData = [];
  }

  return histData;
};
export const updateVisualisationParameter = (
  param,
  minVal,
  maxVal,
  fieldname,
  useVisParam,
  barNumColors,
  activeBarRadius,
  areaNumColors,
  activeBarHeight
) => {
  //const promises = [];

  if (fieldname === "height" && useVisParam) {
    makeHEIGHT_WIDTHJenks(param, minVal, maxVal, true);
  }
  if (fieldname === "width" && useVisParam) {
    makeHEIGHT_WIDTHJenks(param, minVal, maxVal, false);
  }


  for (let i = 0; i < DATAS.bars["features"].length; i++) {
    processEachFeature(
      param,
      minVal,
      maxVal,
      fieldname,
      useVisParam,
      i,
      barNumColors,
      activeBarRadius,
      areaNumColors,
      activeBarHeight
    );
  }
  //return DATAS;
};

const processEachFeature = (
  param,
  minVal,
  maxVal,
  fieldname,
  useVisParam,
  i,
  barNumColors,
  activeBarRadius,
  areaNumColors,
  activeBarHeight
) => {
  let val, colorsSequential, bivariateColors_, areaBivariateColors;

  let numColors = 3;

  if (fieldname === 'color' || fieldname === 'areaColor') {
    if (fieldname === 'color') {
      numColors = barNumColors;
      colorsSequential = getColors2(maxVal + 1);
    } else {
      numColors = areaNumColors;
      colorsSequential = getColors(maxVal + 1);
    }
  } else if (fieldname === "height") {
    numColors = NUMBINSHEIGHT;
  } else if (fieldname === "width") {
    numColors = NUMBINSWIDTH;
  }
  else if (fieldname.indexOf('biv') >= 0) {
    bivariateColors_ = bivariateColors;
  } else
    if (fieldname.indexOf('rBiv') >= 0) {
      areaBivariateColors = bivariateColors2;
    }
  if (!useVisParam) {
    if (fieldname === 'height') {
      val = minVal / 4;
    } else if (fieldname === 'width') {
      val = 0.25;
    }
  } else {
    if (DATAS.bars["features"][i].properties['indices'][param] > -1) {
      val = getJenksIndex(
        //NH change to ALL for the display of parameters. Here is where you can change it to the individual year if you want.
        JENKSARR['all'][param][TOTALNUMJENKSBINS],
        DATAS.bars["features"][i].properties['indices'][param],
      );
      //val is now what it would be if we used 12 jenks. However, we use 3 or 6 in reality, so we divide the value
      val = Math.floor(val / (TOTALNUMJENKSBINS / numColors))
      //adjust value for the visualisation of height and width, which both have a min and max as display.
      if (fieldname === 'height') {
        //val = getNewRangeValue(val, 0, NUMBINSHEIGHT-1, minVal, maxVal);


        val = HEIGHTSTEPS[val];

        // console.log(val)
      } else if (fieldname === 'width') {
        //val = getNewRangeValue(val, 0, NUMBINSWIDTH-1, minVal, maxVal);
        //console.log(val)
        val = RADIUSSTEPS[val];
      }
    } else {
      val = -1;
    }
  }
  //   data.features[i].properties["binValues"][param] = val
  switch (fieldname) {
    case 'color':
      // test[val]+=1
      if (val < 0) {
        DATAS.bars["features"][i].properties[fieldname] = getNoValueColor();
      } else {
        DATAS.bars["features"][i].properties[fieldname] =
          colorsSequential[val];
      }
      break;
    case 'width':
      //console.log(val)
      let newGeometry;
      let circlepoly;
      val = activeBarRadius ? val : 0.15;
      DATAS.bars["features"][i].properties[fieldname] = val;
      //calculate the polygon around the centre point of the bar. This only needs to be done once per point per radius, so it saves a calue in the data to be read when it is needed again later.
      if (val > 0) {
        if (DATAS.bars["features"][i]['geom' + val]) {
          newGeometry = DATAS.bars["features"][i]['geom' + val];
        } else {
          circlepoly = turf.circle(
            [
              DATAS.bars["features"][i].centerCircle.coordinates[0],
              DATAS.bars["features"][i].centerCircle.coordinates[1],
            ],
            val,
            { steps: 6 }, //6 vertices. more vertices will make the bars "rounder", but also impacts rendering performance.
          );
          DATAS.bars["features"][i]['geom' + val] = circlepoly.geometry;
          newGeometry = circlepoly.geometry;
        }
      } else {
        newGeometry = DATAS.bars["features"][i].centerCircle;
      }

      DATAS.bars["features"][i].geometry = newGeometry;
      break;
    case 'height':
      // console.log(val)
      val = activeBarHeight ? val : 1;
      DATAS.bars["features"][i].properties[fieldname] = val;
      break;
    case 'areaColor':
      // //console.log(outlineData)
      if (val < 0) {
        DATAS.areas["features"][i].properties[fieldname] = getNoValueColor();
      } else {
        DATAS.areas["features"][i].properties[fieldname] =
          colorsSequential[val];
      }
      // test[val]+=1
      break;
    case 'biv1':
    case 'biv0':
      //console.log(val)
      DATAS.bars["features"][i].properties[fieldname] = val;
      if (
        val < 0 ||
        DATAS.bars["features"][i].properties['biv1'] === -1 ||
        DATAS.bars["features"][i].properties['biv0'] === -1
      ) {
        DATAS.bars["features"][i].properties['color'] = getNoValueColor();
      } else {
        DATAS.bars["features"][i].properties['color'] =
          bivariateColors_[
          DATAS.bars["features"][i].properties['biv0'] +
          '' +
          DATAS.bars["features"][i].properties['biv1']
          ];
      }

      break;
    case 'rBiv0':
    case 'rBiv1':
      DATAS.areas["features"][i].properties[fieldname] = val;

      if (
        val < 0 ||
        DATAS.areas["features"][i].properties['rBiv1'] === -1 ||
        DATAS.areas["features"][i].properties['rBiv0'] === -1
      ) {
        DATAS.areas["features"][i].properties['areaColor'] = getNoValueColor();
      } else {
        DATAS.areas["features"][i].properties['areaColor'] =
          areaBivariateColors[
          DATAS.areas["features"][i].properties['rBiv0'] +
          '' +
          DATAS.areas["features"][i].properties['rBiv1']
          ];
      }
      break;
    default:
    //console.log("this shouldn't happen");
  }
};

//calculates the data for the neighbourhood histograms. This is based on the proper values of each parameter.
const getHistogramData = (keyArray) => {

  let finalData = [];

  for (let ind = 0; ind < keyArray.length; ind++) {
    let histData = [];
    for (let i = 0; i < TOTALNUMJENKSBINS; i++) {
      // this colour should really be refactored out instead of hardcoded
      let objV = { bin: i, '#CEF0F2': 0 };
      histData.push(objV);

    }
    if (JENKSARR['all'][keyArray[ind]]) {

      //  //console.log(subBins)
      for (let i = 0; i < DATAS.bars["features"].length; i++) {
        let val;

        val = getJenksIndex(
          JENKSARR["all"][keyArray[ind]][TOTALNUMJENKSBINS],
          DATAS.bars["features"][i].properties['indices'][keyArray[ind]],
        );


        if (val !== -1) {
          if (
            DATAS.bars["features"][i]['properties']['indices'][keyArray[ind]] !== -1
          ) {
            histData[val]['#CEF0F2'] +=
              DATAS.bars["features"][i]['properties']['indices'][keyArray[ind]];
          }
        }
      }

      finalData[keyArray[ind]] = histData;
    } else {
      histData = [];
    }
  }

  // //console.log(finalData)
  return finalData;
  //setHistogramData(finalData);
};

export const getBivariateHistograms = (
  isRegion,
  paramVert,
  paramHori,
) => {
  //console.log("makeBivariateHistograms " + isRegion)
  let histData_vertical = [];
  let histData_horizontal = [];


  for (let i = 0; i < TOTALNUMJENKSBINS; i++) {
    let objV = { bin: Math.floor(i / 4) + '' + i };
    let objH = { bin: Math.floor(i / 4) + '' + i };
    for (let k = 0; k < 3; k++) { //this adds the 3 possible colours in each bin
      objV[Math.floor(i / 4) + '' + k] = 0;
      objH[k + '' + Math.floor(i / 4)] = 0;
    }
    histData_vertical.push(objV);
    histData_horizontal.push(objH);
  }


  histData_vertical = fillHistogramArray(histData_vertical, true, paramVert, isRegion)
  histData_horizontal = fillHistogramArray(histData_horizontal, false, paramHori, isRegion)

  return [histData_vertical, histData_horizontal];
};

//takes the array and fills it correctly with the values corresponding to the colour in the bivariate legend. 
const fillHistogramArray = (arr, isVertical, param, isRegion) => {
  let property0, property1;
  if (isRegion) {
    property0 = 'rBiv0';
    property1 = 'rBiv1';
  } else {
    property0 = 'biv0';
    property1 = 'biv1';
  }

  if (JENKSARR['all'][param]) {

    for (let i = 0; i < DATAS.bars["features"].length; i++) {
      let biv0, biv1;
      if (isRegion) {
        biv0 = DATAS.areas["features"][i]['properties'][property0];
        biv1 = DATAS.areas["features"][i]['properties'][property1];
      } else {
        biv0 = DATAS.bars["features"][i]['properties'][property0];
        biv1 = DATAS.bars["features"][i]['properties'][property1];
      }
      let comparator = isVertical ? biv0 : biv1;

      if (biv1 !== -1 && biv0 !== -1) {
        //reuse JenksIndex, should be renamed. This will return the position in the array the given value falls in
        let val = getJenksIndex(
          JENKSARR["all"][param][TOTALNUMJENKSBINS],
          DATAS.bars["features"][i].properties['indices'][param],
        );
        //val will be something between 0 and 11 and used to ID the column for this colour.
        if (val !== -1) {
          for (let j = 0; j < arr.length; j++) {
            if (arr[j]['bin'] === comparator + '' + val) { //here, the column (or bin) is determined
              //now that we know in which column we are based on the value, we add the value to the correct colour using the biv0 and biv 1 values of the data. These will always be between 0 and 2 and used to determine 
              //the colour from the bivariateColors field.
              arr[j][biv0 + '' + biv1] +=
                DATAS.bars["features"][i]['properties']['indices'][param]

            }
          }
        }
      }
    }
  } else {
    arr = [];
  }
  return arr
}