import React from 'react';
import { Drawer, IconButton, ThemeProvider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { customTheme } from './CommonComponents.js';
import StackedHistogram from './StackedHistogram.js';
import { getIndexText, renderNumber } from './Helpers.js';

const NeighbourhoodCharacteristics = ({
  city,
  open,
  selectionDetails,
  closeHandler = () => {},
  data,
}) => {
  const displaySelection = (index) => {
    if (!selectionDetails) return <></>;
    const { properties } = selectionDetails;

    let text = '';
    properties.forEach((d) => {
      if (d.index === index) {
        text = `${getIndexText(d.indexClass)} (${renderNumber(
          d.index,
          d.value,
        )})`;
      }
    });
    return text;
  };

  const getRange = (index) => {
   // console.log("getRange " + index)
    if (!selectionDetails) return [];
    const { properties } = selectionDetails;

    let value;
    properties.forEach((d) => {
      console.log(d.index +  "    "  + index)
      if (d.index === index) {
        value = d.value;
      }
    });
   // console.log(value)
    return value ? [value] : [];
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Drawer
        anchor="left"
        open={open}
        variant={'persistent'}
        className="neighbourhood"
      >
        <div className="flex-justified">
          <h3>Neighbourhood Characteristics</h3>
          <IconButton
            style={{ position: 'relative', left: '1em' }}
            aria-label="close"
            onClick={closeHandler}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {data ? (
          Object.keys(data).map((key, i) => {
            // Hide population size in the UI for now, since it is not normalised
            if (key === 'Population') return '';
            return (
              <>
                <div key={'hist' + i}>
                  <h4>{key}</h4>
                  <p className="neighbourhood-selection">
                    {displaySelection(key)}
                  </p>
                  <StackedHistogram
                    data={data[key]}
                    keys={['#CEF0F2']}
                    colors={['#CEF0F2']}
                    width={200}
                    height={40}
                    linear={true}
                    annotationValues={getRange(key)}
                  />
                  <div className="neighbourhood-line flex-centre linear-line">
                    <span>Low</span>
                    <span className="long-dash"></span>
                    <span>High</span>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <></>
        )}
      </Drawer>
    </ThemeProvider>
  );
};

export default NeighbourhoodCharacteristics;
