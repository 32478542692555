import * as helper from './Helpers.js';

import {MapFunctionsHook} from './MapFunctions_hook';
import React, {
  Fragment,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as Constants from './Constants.js';
import NeighbourhoodCharacteristics from './NeighbourhoodCharacteristics.js';
import StackedHistogram from './StackedHistogram.js';


/**
 * Manages the model of the data and recalculates the data based on input of the user.
 */

export const DataManager = (props) => {

  //city and year data
  const [updateData, setUpdateData] = useState(false);

  const [popupData, setPopupData] = useState(null);
  const [histogramData, setHistogramData] = useState();
  const [statsVisible, setStatsVisible] = useState(false);
  const [postcodeFilter, setPostcodeFilter] = useState(null);
  const [SA3Filter, setSA3Filter] = useState(null);
  const [colourFilter, setColourFilter] = useState(null);
  const [regionColourFilter, setRegionColourFilter] = useState(null);
  const [heightFilter,setHeightFilter] = useState(null)
  const [radiusFilter,setRadiusFilter] = useState(null)
  //const [currCity, setCity] = useState();
  const currCity = useRef();
  const currYear = useRef();
  const currUrl = useRef()
  const zoom = useRef(0.35);

  const parameterCopy = useRef([props.col, props.biv1, props.biv2, props.rCol, props.rBiv1, props.rBiv2, props.height, props.width]);

  const [hoverHistRegion, setHoverHistRegion] = useState(null);
  const [hoverHistBars, setHoverHistBars] = useState(null);

  const activeBarRadius = useRef(props.activeBarRadius);
  const activeBarHeight = useRef(props.activeBarHeight);
  const barColorTypeRef = useRef(props.barColorType);
  const barNumColorsRef = useRef(props.barNumColors);
  const areaColorTypeRef = useRef(props.areaColorType);
  const areaNumColorsRef = useRef(props.areaNumColors);
  

  const allLoaded = useRef(false)
  useEffect(() => {
    const initParameters = () => {
      console.log("initParameters")
      const myCallbackFunction = props.linCallback;
      const bivCallbackFunction = props.bivCallback;
      //console.log(propsref.current)
      parameterCopy.current = [props.col, props.biv1, props.biv2, props.rCol, props.rBiv1, props.rBiv2, props.height, props.width]
      //console.log(props.allIndices)
      //helper.setData(props.selectorStructures,props.allIndices,props.currJenks);
      setHistogramData(helper.expHistogramData())
      //console.log(parameterCopy.current)
      if (barColorTypeRef.current === 'linear') {
        helper.updateVisualisationParameter(
          parameterCopy.current[Constants.COLOR],
          0,
          barNumColorsRef.current - 1,
          Constants.PARAMETERNAMES[Constants.COLOR],
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current);
      } else {
       helper.updateVisualisationParameter(
          parameterCopy.current[Constants.BIVARIATE1],
          0,
          2,
          'biv0',
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );

       helper.updateVisualisationParameter(
          parameterCopy.current[Constants.BIVARIATE2],
          0,
          2,
          'biv1',
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
      }
      if (areaColorTypeRef.current === 'linear') {
        helper.updateVisualisationParameter(
          parameterCopy.current[Constants.REGIONCOLOR],
          0,
          areaNumColorsRef.current - 1,
          Constants.PARAMETERNAMES[Constants.REGIONCOLOR],
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
      } else {
        helper.updateVisualisationParameter(
          parameterCopy.current[Constants.REGIONBIVARIATE1],
          0,
          2,
          'rBiv0',
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
       helper.updateVisualisationParameter(
          parameterCopy.current[Constants.REGIONBIVARIATE2],
          0,
          2,
          'rBiv1',
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
      }
       helper.updateVisualisationParameter(
        parameterCopy.current[Constants.HEIGHT],
        helper.MINHEIGHT,
        helper.MAXHEIGHT,
        Constants.PARAMETERNAMES[Constants.HEIGHT],
        true,
        barNumColorsRef.current,
        activeBarRadius.current,
        areaNumColorsRef.current,
        activeBarHeight.current
      );
       helper.updateVisualisationParameter(parameterCopy.current[Constants.WIDTH],
        helper.MINWIDTH,
        zoom.current,
        Constants.PARAMETERNAMES[Constants.WIDTH],
        true,
        barNumColorsRef.current,
        activeBarRadius.current,
        areaNumColorsRef.current,
        activeBarHeight.current);
      // const datas =helper.getDatas();
     setUpdateData(!updateData)
     
      

      if (barColorTypeRef.current === 'multi') {

        let histogram_datas = helper.getBivariateHistograms(false,  parameterCopy.current[Constants.BIVARIATE1], parameterCopy.current[Constants.BIVARIATE2])
        bivCallbackFunction(false, [
          <StackedHistogram
            data={histogram_datas[0]}
            keys={helper.verticalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
          <StackedHistogram
            data={histogram_datas[1]}
            keys={helper.horizontalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
        ]);
      } else {
 
        myCallbackFunction(false, [
          <StackedHistogram

            data={helper.getLinearHistograms(false, parameterCopy.current[Constants.COLOR])}
            key={0}
            keys={
              helper.getColors2(barNumColorsRef.current)
            }
            colors={
              helper.getColors2(barNumColorsRef.current)
            }
            hoverCallback={histHoverCallback}
            isRegion={false}
            linear={true}
          />,
        ]);

      }


      if (areaColorTypeRef.current === 'multi') {
        ////console.log('blop');
        let histogram_datas = helper.getBivariateHistograms(true,parameterCopy.current[Constants.REGIONBIVARIATE1], parameterCopy.current[Constants.REGIONBIVARIATE2])
        bivCallbackFunction(true, [
          <StackedHistogram
            data={histogram_datas[0]}
            keys={helper.verticalKeysBiv}
            colors={helper.bivariateColors2}
            hoverCallback={histHoverCallback}
            isRegion={true}
          />,
          <StackedHistogram
            data={histogram_datas[1]}
            keys={helper.horizontalKeysBiv}
            colors={helper.bivariateColors2}
            hoverCallback={histHoverCallback}
            isRegion={true}
          />,
        ]);;

      } else {
        //    //console.log(datasets)
        myCallbackFunction(true, [
          <StackedHistogram
            data={helper.getLinearHistograms(true, parameterCopy.current[Constants.REGIONCOLOR])}
            key={0}
            keys={
              helper.getColors(areaNumColorsRef.current)
            }
            colors={
              helper.getColors(areaNumColorsRef.current)
            }
            hoverCallback={histHoverCallback}
            isRegion={true}
            linear={true}
          />,
        ]);
      }

    };

    if (props.structuresLoaded) {
  
      //helper.setJenksArr(props.currJenks);
      allLoaded.current = true;

      if (
        props.yearSync !== currYear.current &&
        currCity.current === props.citySync
      ) {
        //console.log('year: ' + props.yearSync);
        currYear.current = props.yearSync;

        initParameters();
        setStatsVisible(false);

        return;
      }
      if (props.citySync !== currCity.current || props.currUrl !== currUrl.current) {
        //console.log(props.citySync);
        currCity.current = props.citySync;
        currYear.current = props.yearSync;
        currUrl.current = props.currUrl;
        initParameters();
        return;
      }
      //console.log(props.selectorStructures)
    }
  }, [
    props.yearSync,
    props.citySync,
    props.structuresLoaded,
    props.bivCallback,
    props.linCallback,
    props.tagChanged
  ]);

  useEffect(() => {
    if(props.height===undefined || props.width===undefined)
      return;
    let heightChange = (props.height !== parameterCopy.current[Constants.HEIGHT] || activeBarHeight.current !== props.activeBarHeight) 

    parameterCopy.current[Constants.WIDTH] =
      props.width;
    parameterCopy.current[Constants.HEIGHT] =
      props.height;
    activeBarRadius.current = props.activeBarRadius;
    activeBarHeight.current = props.activeBarHeight;

    if (
      allLoaded.current && !heightChange && props.width!==undefined
    ) {

      helper.updateVisualisationParameter(props.width,
        helper.MINWIDTH,
        zoom.current,
        Constants.PARAMETERNAMES[Constants.WIDTH],
        props.width!=="null",
        barNumColorsRef.current,
        activeBarRadius.current,
        areaNumColorsRef.current,
        activeBarHeight.current);

      // const datas =helper.getDatas()
     setUpdateData(!updateData)
   
 

      activeBarRadius.current = props.activeBarRadius

      //setUpdateData(!updateData);
    } else if (
      allLoaded.current && heightChange
    ) {
      helper.updateVisualisationParameter(
        props.height,
        helper.MINHEIGHT,
        helper.MAXHEIGHT,
        Constants.PARAMETERNAMES[Constants.HEIGHT],
        props.height!=="null",
         barNumColorsRef.current,
        activeBarRadius.current,
        areaNumColorsRef.current,
        activeBarHeight.current
      );

     // // const datas =helper.getDatas();
      setUpdateData(!updateData)


      activeBarHeight.current = props.activeBarHeight
      //setUpdateData(!updateData);
    }
  }, [props.width, props.height, props.activeBarRadius, props.activeBarHeight]);



  useEffect(() => {

    const myCallbackFunction = props.linCallback;
    const bivCallbackFunction = props.bivCallback;
    let biv1Change = props.biv1 !== parameterCopy.current[Constants.BIVARIATE1]
    let biv2Change = props.biv2 !== parameterCopy.current[Constants.BIVARIATE2]
    let colChange = props.col !== parameterCopy.current[Constants.COLOR]
    parameterCopy.current[Constants.BIVARIATE1] =
      props.biv1;
    parameterCopy.current[Constants.BIVARIATE2] =
      props.biv2;
    parameterCopy.current[Constants.COLOR] =
      props.col;
    barColorTypeRef.current = props.barColorType;
    barNumColorsRef.current = props.barNumColors;
    if (allLoaded.current) {

      if (biv1Change) {
        helper.updateVisualisationParameter(
          props.biv1,
          0,
          2,
          'biv0',
          true,
           barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
       
 
        let histogram_datas = helper.getBivariateHistograms(false, props.biv1, props.biv2)
        bivCallbackFunction(false, [
          <StackedHistogram
            data={histogram_datas[0]}
            keys={helper.verticalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
          <StackedHistogram
            data={histogram_datas[1]}
            keys={helper.horizontalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
        ]);


      } else if (biv2Change) {
         helper.updateVisualisationParameter(
          props.biv2,
          0,
          2,
          'biv1',
          true,
           barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );

        let histogram_datas = helper.getBivariateHistograms(false, props.biv1, props.biv2)
        bivCallbackFunction(false, [
          <StackedHistogram
            data={histogram_datas[0]}
            keys={helper.verticalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
          <StackedHistogram
            data={histogram_datas[1]}
            keys={helper.horizontalKeysBiv}
            colors={helper.bivariateColors}
            hoverCallback={histHoverCallback}
            isRegion={false}
          />,
        ]);


      } else if (colChange) {

         helper.updateVisualisationParameter(
          props.col,
          0,
          props.barNumColors - 1,
          Constants.PARAMETERNAMES[Constants.COLOR],
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
        myCallbackFunction(false, [
          <StackedHistogram
            data={helper.getLinearHistograms(false, props.col)}
            key={0}
            keys={
              helper.getColors2(props.barNumColors)
            }
            colors={
              helper.getColors2(props.barNumColors)
            }
            hoverCallback={histHoverCallback}
            isRegion={false}
            linear={true}
          />,
        ]);


      } else {
        //colourtype or barNumcolors has changed, so this applies
        if (props.barColorType === 'multi') {
          helper.updateVisualisationParameter(
            props.biv1,
            0,
            2,
            'biv0',
            true,
            barNumColorsRef.current,
            activeBarRadius.current,
            areaNumColorsRef.current,
            activeBarHeight.current
          );
          helper.updateVisualisationParameter(
            props.biv2,
            0,
            2,
            'biv1',
            true,
             barNumColorsRef.current,
            activeBarRadius.current,
            areaNumColorsRef.current,
            activeBarHeight.current
          );
          let histogram_datas = helper.getBivariateHistograms(false,   props.biv1, props.biv2)
          bivCallbackFunction(false, [
            <StackedHistogram
              data={histogram_datas[0]}
              keys={helper.verticalKeysBiv}
              colors={helper.bivariateColors}
              hoverCallback={histHoverCallback}
              isRegion={false}
            />,
            <StackedHistogram
              data={histogram_datas[1]}
              keys={helper.horizontalKeysBiv}
              colors={helper.bivariateColors}
              hoverCallback={histHoverCallback}
              isRegion={false}
            />,
          ]);
        } else {
           helper.updateVisualisationParameter(
            props.col,
            0,
            props.barNumColors - 1,
            Constants.PARAMETERNAMES[Constants.COLOR],
            true,
            barNumColorsRef.current,
            activeBarRadius.current,
            areaNumColorsRef.current,
            activeBarHeight.current
          );
          myCallbackFunction(false, [
            <StackedHistogram
              data={helper.getLinearHistograms(false, props.col)}
              key={0}
              keys={
                helper.getColors2(props.barNumColors)
              }
              colors={
                helper.getColors2(props.barNumColors)
              }
              hoverCallback={histHoverCallback}
              isRegion={false}
              linear={true}
            />,
          ]);
        }
      }
      // const datas =helper.getDatas();
     setUpdateData(!updateData)
 

      //setUpdateData(!updateData);
    }
  }, [props.barColorType, props.barNumColors, props.biv1, props.biv2, props.col, props.bivCallback, props.linCallback]);

  useEffect(() => {
    //console.log("areacolortype,num,params")
    const myCallbackFunction = props.linCallback;
    const bivCallbackFunction = props.bivCallback;
    let rBiv1Change = props.rBiv1 !==
      parameterCopy.current[Constants.REGIONBIVARIATE1]
    let rBiv2Change = props.rBiv2 !==
      parameterCopy.current[Constants.REGIONBIVARIATE2]
    let rColChange = props.rCol !==
      parameterCopy.current[Constants.REGIONCOLOR]
    parameterCopy.current[Constants.REGIONBIVARIATE1] =
      props.rBiv1;
    parameterCopy.current[Constants.REGIONBIVARIATE2] =
      props.rBiv2;
    parameterCopy.current[Constants.REGIONCOLOR] =
      props.rCol;
    areaColorTypeRef.current = props.areaColorType;
    areaNumColorsRef.current = props.areaNumColors;
    if (allLoaded.current) {


      if (rBiv1Change) {
       helper.updateVisualisationParameter(
          props.rBiv1,
          0,
          2,
          'rBiv0',
          true,
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current
        );
        let histogram_datas = helper.getBivariateHistograms(true, props.rBiv1, props.rBiv2)
        bivCallbackFunction(true, [
          <StackedHistogram
            data={histogram_datas[0]}
            keys={helper.verticalKeysBiv}
            colors={helper.bivariateColors2}
            hoverCallback={histHoverCallback}
            isRegion={true}
          />,
          <StackedHistogram
            data={histogram_datas[1]}
            keys={helper.horizontalKeysBiv}
            colors={helper.bivariateColors2}
            hoverCallback={histHoverCallback}
            isRegion={true}
          />,
        ]);

      } else
        if (rBiv2Change
        ) {
           helper.updateVisualisationParameter(
            props.rBiv2,
            0,
            2,
            'rBiv1',
            true,
            barNumColorsRef.current,
            activeBarRadius.current,
            areaNumColorsRef.current,
            activeBarHeight.current
          );
          let histogram_datas = helper.getBivariateHistograms(true,   props.rBiv1, props.rBiv2)
          bivCallbackFunction(true, [
            <StackedHistogram
              data={histogram_datas[0]}
              keys={helper.verticalKeysBiv}
              colors={helper.bivariateColors2}
              hoverCallback={histHoverCallback}
              isRegion={true}
            />,
            <StackedHistogram
              data={histogram_datas[1]}
              keys={helper.horizontalKeysBiv}
              colors={helper.bivariateColors2}
              hoverCallback={histHoverCallback}
              isRegion={true}
            />,
          ]);


        } else
          if (rColChange) {
             helper.updateVisualisationParameter(
              props.rCol,
              0,
              areaNumColorsRef.current - 1,
              Constants.PARAMETERNAMES[Constants.REGIONCOLOR],
              true,
              barNumColorsRef.current,
              activeBarRadius.current,
              areaNumColorsRef.current,
              activeBarHeight.current
            );
            myCallbackFunction(true, [
              <StackedHistogram
                data={helper.getLinearHistograms(true,  props.rCol)}
                key={0}
                keys={
                  helper.getColors(areaNumColorsRef.current)
                }
                colors={
                  helper.getColors(areaNumColorsRef.current)
                }
                hoverCallback={histHoverCallback}
                isRegion={true}
                linear={true}
              />,
            ]);


          } else {
            if (areaColorTypeRef.current === 'multi') {
             helper.updateVisualisationParameter(
                props.rBiv1,
                0,
                2,
                'rBiv0',
                true,
                 barNumColorsRef.current,
                activeBarRadius.current,
                areaNumColorsRef.current,
                activeBarHeight.current
              );
              helper.updateVisualisationParameter(
                props.rBiv2,
                0,
                2,
                'rBiv1',
                true,
                
                barNumColorsRef.current,
                activeBarRadius.current,
                areaNumColorsRef.current,
                activeBarHeight.current
              );
              let histogram_datas = helper.getBivariateHistograms(true,props.rBiv1, props.rBiv2)
              bivCallbackFunction(true, [
                <StackedHistogram
                  data={histogram_datas[0]}
                  keys={helper.verticalKeysBiv}
                  colors={helper.bivariateColors2}
                  hoverCallback={histHoverCallback}
                  isRegion={true}
                />,
                <StackedHistogram
                  data={histogram_datas[1]}
                  keys={helper.horizontalKeysBiv}
                  colors={helper.bivariateColors2}
                  hoverCallback={histHoverCallback}
                  isRegion={true}
                />,
              ]);
            } else {
               helper.updateVisualisationParameter(
                props.rCol,
                0,
                areaNumColorsRef.current - 1,
                Constants.PARAMETERNAMES[Constants.REGIONCOLOR],
                true,
                 barNumColorsRef.current,
                activeBarRadius.current,
                areaNumColorsRef.current,
                activeBarHeight.current
              );
              myCallbackFunction(true, [
                <StackedHistogram
                  data={helper.getLinearHistograms(true,  props.rCol)}
                  key={0}
                  keys={
                    helper.getColors(areaNumColorsRef.current)
                  }
                  colors={
                    helper.getColors(areaNumColorsRef.current)
                  }
                  hoverCallback={histHoverCallback}
                  isRegion={true}
                  linear={true}
                />,
              ]);
            }
          }
          // const datas =helper.getDatas();
          setUpdateData(!updateData)
    

    }
  }, [props.areaColorType, props.areaNumColors, props.rBiv1, props.rBiv2, props.rCol, props.activeBarRadius, props.bivCallback, props.linCallback]);
  //region colour filter
  useEffect(() => {
    //console.log("hoverregion")
    let arr = [...props.activeAreaColors];
  
    if (props.hoverColourRegion !== null) arr.push(props.hoverColourRegion);

    if (hoverHistRegion !== null) arr.push(hoverHistRegion);
    //console.log(arr)
    if (arr.length === 0) {
      setRegionColourFilter(null);
    } else {
      setRegionColourFilter(['in', ['get', 'areaColor'], ['literal', arr]]);
    }
  }, [props.activeAreaColors, props.hoverColourRegion, hoverHistRegion]);

  //sends postcode and SA3 filter to the Map component
  useEffect(() => {
    let postFilter =
      props.activePostcodes.length > 0
        ? ['in', ['get', 'postcode'], ['literal', props.activePostcodes]]
        : null;
    let SA3Filter =
      props.activeSA3s.length > 0
        ? [
          'in',
          [
            'get',
            helper.getComparatorsByCity(currCity.current)[currYear.current][
            'SA3'
            ],
          ],
          ['literal', props.activeSA3s],
        ]
        : null;
    setPostcodeFilter(postFilter);
    setSA3Filter(SA3Filter);
  }, [props.activePostcodes, props.activeSA3s]);

  //barfilter
  useEffect(() => {
    //console.log("hoverBars")
    let arr = [...props.activeBarColors];

    
    if (props.hoverColourBar !== null) arr.push(props.hoverColourBar);
 

    if (hoverHistBars !== null) arr.push(hoverHistBars);
    //      //console.log(arr)
    if (arr.length === 0) {
      setColourFilter(null);
    } else {
      setColourFilter(['in', ['get', 'color'], ['literal', arr]]);
    }
  }, [props.activeBarColors,   props.hoverColourBar, hoverHistBars]);

  useEffect(()=> {
    let heightArr = [...props.activeHeights]
  
    if(props.hoverHeight!==null) heightArr.push(props.hoverHeight);
    let tempArr = []
    for(let i = 0; i < heightArr.length; i++){
      tempArr.push(helper.HEIGHTSTEPS[heightArr[i]])
    }

    if (tempArr.length === 0) {
      setHeightFilter(null);
    } else {
      setHeightFilter(['in', ['get', 'height'], ['literal', tempArr]]);
    }
  }, [props.hoverHeight, props.activeHeights])

  useEffect(() => {
    let radiusArr = [...props.activeRadii];

    if(props.hoverRadius!==null) radiusArr.push(props.hoverRadius);
    let tempArr = []
    for(let i = 0; i < radiusArr.length; i++){
      tempArr.push(helper.RADIUSSTEPS[radiusArr[i]])
    }
   // console.log(numberArr)
    if (tempArr.length === 0) {
      setRadiusFilter(null);
    } else {
      setRadiusFilter(['in', ['get', 'width'], ['literal', tempArr]]);
    }
  },[props.activeRadii, props.hoverRadius])

  const histHoverCallback = (key, isRegion) => {
    if (key !== null && key.length > 2) {
      //ugly but this means we are in the linear histogram.
      if (isRegion) {
        setHoverHistRegion(key !== null ? key : null);
      } else {
        setHoverHistBars(key !== null ? key : null);
      }
    } else {
      if (isRegion) {
        setHoverHistRegion(key !== null ? helper.bivariateColors2[key] : null);
      } else {
        setHoverHistBars(key !== null ? helper.bivariateColors[key] : null);
      }
    }
  };



  const zoomCallback = (val) => {
    //console.log(val)
    let change = false
    if (props.flexRadius) {
      if (val < 11 && zoom.current !== 0.35) {
        zoom.current = 0.35;
        change = true

      } else if (val >= 11 && zoom.current !== 0.2) {
        zoom.current = 0.2;
        change = true
      }
      if (change) {
       
        helper.updateVisualisationParameter(parameterCopy.current[Constants.WIDTH],
          0.05,
          zoom.current,
          Constants.PARAMETERNAMES[Constants.WIDTH],
          true,
          
          barNumColorsRef.current,
          activeBarRadius.current,
          areaNumColorsRef.current,
          activeBarHeight.current)
          
          setUpdateData(!updateData)
      }
    } else {
      //radius is not flex, so we ignore zoom callbacks.
      return;
    }
  };
  //each entry in the dataset (each "bar")


  const passPopupData = useCallback(
    (d) => {
      setPopupData(d);
    },
    [setPopupData],
  );

  const openNeighbourhoodDetails = useCallback(() => {
    setStatsVisible(true);
  }, [setStatsVisible]);

  const closeNeighbourhoodDetails = useCallback(() => {
    setStatsVisible(false);
  }, [setStatsVisible]);

  //updates the data according to the vis parameter in the dataset.

  return (
    <Fragment>
      <MapFunctionsHook
        //data={datasets[0]}
        //regiondata={datasets[1]}
        year={props.yearSync}
        city={props.citySync}
        mapStyle={props.mapStyle}
        updateData={updateData}
        showBars={props.barsActive}
        showRegions={props.regionActive}
        barTransparency={props.barTransparency}
        areaTransparency={props.areaTransparency}
        zoomCallback={zoomCallback}
        colourFilter={colourFilter}
        postcodeFilter={postcodeFilter}
        heightFilter = {heightFilter}
        radiusFilter = {radiusFilter}
        SA3Filter={SA3Filter}
        regionColourFilter={regionColourFilter}
        barClickCallback={props.barClickCallback}
        neighbourhoodCallback={passPopupData}
        closePopupCallback={closeNeighbourhoodDetails}
        openNeighbourhoodCallback={openNeighbourhoodDetails}
        // clickCallback={props.clickCallback}.
        dataAvail={props.structuresLoaded}
        biv1 = {props.biv1}
        biv2 = {props.biv2}
        width= {props.width}
        height= {props.height}
        rBiv1= {props.rBiv1}
        rBiv2= {props.rBiv2}
        col= {props.col}
        rCol= {props.rCol}
        barColorType= {props.barColorType}
        areaColorType= {props.areaColorType}
      />
      <NeighbourhoodCharacteristics
        city={props.citySync}
        open={statsVisible}
        selectionDetails={popupData}
        closeHandler={closeNeighbourhoodDetails}
        data={histogramData}
      />
    </Fragment>
  );
};

