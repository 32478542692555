import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { getIndexTooltips } from './Helpers';
import AuckPDF from './pdfs/Superdiversity-website-metadata-Auckland.pdf';
import SydPDF from './pdfs/Superdiversity-website-metadata-Sydney.pdf';
import VancPDF from './pdfs/Superdiversity-website-metadata-Vancouver.pdf';
import './styles/index.css';

export default function MenuDataDictionary({ city }) {
  const indices = [
    'Population Size',
    'Ethnic Diversity',
    'Residential Turnover',
    'Arrival-wave Diversity',
    'Income Diversity',
    'Educational Diversity',
  ];

  const getCityPdf = (city) => {
    switch (city) {
      case 'Vancouver':
        return VancPDF;
      case 'Sydney':
      case 'Melbourne': // same sheet
        return SydPDF;
      case 'Auckland':
        return AuckPDF;
      default:
        return '';
    }
  };

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="data-dictionary"
      >
        <h2>Data Dictionary</h2>
      </AccordionSummary>
      <AccordionDetails
        style={{ paddingBottom: '64px' }}
        className="data-dictionary"
      >
        {/* This outer div is necessary since M-UI defaults to flexbox inside here */}
        <div>
          {indices.map((index) => (
            <div key={index}>
              <h3>{index}</h3>
              <p>{getIndexTooltips(index)}</p>
            </div>
          ))}
          <div className="margin-top">
            <Button
              variant="contained"
              color="default"
              href={getCityPdf(city)}
              target="_blank"
            >
              <PictureAsPdf />
              <span className="margin-left">Open {city} Data PDF</span>
            </Button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
