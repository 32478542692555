import * as helper from './Helpers.js';
import React, { Fragment, useRef, useEffect, useState } from 'react';
import { DataManager } from './DataManager.js';
import spinner from './images/spinner.gif';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

/**DataLoader deals with any loading and preprocessing of the data.
 * Data loaded: cities/years, postcodes, Jenks files, spinner gif
 *  Based on the parameters passed down from UrbanSuperdiversity.js, the correct year and city will be loaded.
 * it also prepares data to display the histograms correctly 
 * @param {*} props Menu status passed down from UrbanSuperdiversity.js 

**/
export const DataLoader = (props) => {
  const [selectorStructures, setSelectorStructures] = useState({
    loaded: false,
    //city: '',
   // year: -1,
    currJenks: {}
  });
  //const [currJenks, setCurrJenks] = useState(null);
  const loadedPostcodes = useRef(false);
  const currCity = useRef();
  const currYear = useRef();
  //const tagChanged = useState(false);
  const currLoadUrl = useRef();
  const [isLoading, setLoading] = useState(true);

  /**load the postcodes and saves them in postcodesCities
   * currently there is onnly one file for Australia, which is used for Australian cities for 2011/2016.
   * @return {postcodesCities} postcodesCities - literal which includes the postcode data.
   **/
  async function GetPostcodes(url) {
    if (url === undefined || url === null) {
      return null;
    }
    let postcodes = await helper.parseCsv(
      process.env.PUBLIC_URL + url,
    );

    let finalData = [];
    for (let i = 1; i < postcodes.data.length; i++) {
      finalData[postcodes.data[i][0]] = postcodes.data[i][1];
    }
    /* let postcodesCities = {
       Melbourne: { 2011: finalData, 2016: finalData },
       Sydney: { 2011: finalData, 2016: finalData },
     };*/
    //console.log(postcodesCities)
    return finalData;
  } //do this only once

  useEffect(() => {
    //console.log(props.loadUrl)
    /**
     *
     * @param {*} dataset The dataset to prep
     * @param {*} jenks The jenks info to incorporate
     */

    const prepDatasets = (dataset, jenks) => {
      // console.log('prep Dataset');
      let features = dataset.features;
      //props.parametersLoadedCallback(Object.keys(features[0].properties['indices']))
      let i, j;
      if (
        helper.getComparatorsByCity(props.city) !== undefined &&
        helper.getComparatorsByCity(props.city) !== null
      ) {
        for (i = 0; i < features.length; i++) {
          features[i].centerCircle = features[i].geometry; //store the center circle for each feature
          let propertyKeys = Object.keys(features[i].properties['indices']);

          features[i].properties['indexClasses'] = {};
          features[i].properties['binValues'] = [];
          // Replace the short property keys with the long ones
          for (j = 0; j < propertyKeys.length; j++) {
            let shortPropertyName = propertyKeys[j];

            // This property 'Income-SE' appear to be unused, or invalid in the Vancouver dataset
            if (
              shortPropertyName === 'Income-SE' ||
              shortPropertyName.indexOf('old') >= 0
            ) {
              delete features[i].properties['indices'][shortPropertyName];
              continue;
            }
            // Ethnic diversity is not used in superdiversity maps, but the per capita ones are.
            // Remove the raw count for ethnic diversity from the interface
            if (
              shortPropertyName === 'Ethnicity-raw-count' ||
              shortPropertyName === 'Ethnicity-count'
            ) {
              delete features[i].properties['indices'][shortPropertyName];
              continue;
            }
            // Copying values
            let longIndexName = helper.getFinalIndexName(shortPropertyName);

            if (longIndexName === undefined) {
              //if no long index is defined, we keep the short index
              longIndexName = shortPropertyName;
            }

            let indexValue = parseFloat(
              features[i].properties['indices'][propertyKeys[j]],
            );

            features[i].properties['indices'][longIndexName] = indexValue;

            // cache the 3-class Jenks index for each property feature
            // Dig into each year as jenks are calculated for the whole series
            // This bit depends on getIndicesForCity() in Helper.js matching the data
            //NH divide the jenks index by 4 because now the Jenks always has 12 bins.
            //console.log(jenks)
            if (jenks && jenks[longIndexName]) {
              features[i].properties['indexClasses'][
                longIndexName
              ] = Math.floor(helper.getJenksIndex(jenks[longIndexName][12], indexValue) / 4);
            }
            if (shortPropertyName !== longIndexName) {
              //only delete the original entry if there is a long Index name defined to replace it.
              delete features[i].properties['indices'][propertyKeys[j]];
            }
          }
          features[i].properties['biv0'] = '0';
          features[i].properties['biv1'] = '0';
          features[i].properties['rBiv0'] = '0';
          features[i].properties['rBiv1'] = '0';
          features[i].properties['areaColor'] = '#ffffff';
        }
      }
    };

    /**
     *
     * @param {*} selectorSet the set defining the bars on the map
     * @param {*} outlineSet the set defining the outlines on the map
     * @param {*} jenksSet the jenks set for the selected City (contains info for each year, and over all years available)
     * @param {*} postcodesAll the postcode data for the city
     */
    const enhanceDatasets = (
      selectorSet,
      outlineSet,
      jenksSet,
      postcodes,
    ) => {

      prepDatasets(selectorSet, jenksSet);
      const myCallbackFunction = props.postcodeCallback;

      let tmpPostcodes = [];
      let tmpSA3s = [];
      let lookups = null;
      lookups = helper.getComparatorsByCity(props.city)[props.year];

      for (let i = 0; i < selectorSet.features.length; i++) {
        if (lookups !== undefined) {
          outlineSet.features[i].properties[lookups['SA3']] =
            selectorSet.features[i].properties[lookups['SA3']];
          if (
            tmpSA3s.indexOf(
              selectorSet.features[i].properties[lookups['SA3']],
            ) < 0
          ) {
            tmpSA3s.push(selectorSet.features[i].properties[lookups['SA3']]);
          }

          if (postcodes !== null && postcodes !== undefined) {
            let key = selectorSet.features[i].properties[lookups['sa1']];
            if (key in postcodes) {
              selectorSet.features[i].properties['postcode'] = postcodes[key];
              outlineSet.features[i].properties['postcode'] = postcodes[key];
              if (tmpPostcodes.indexOf(postcodes[key]) < 0)
                tmpPostcodes.push(postcodes[key]);
            }
          }
        }
      }
      selectorSet.postcodes = tmpPostcodes;
      selectorSet.SA3s = tmpSA3s;
      outlineSet.postcodes = tmpPostcodes;
      outlineSet.SA3s = tmpSA3s;

      myCallbackFunction(selectorSet.postcodes, selectorSet.SA3s);
      loadedPostcodes.current = true;
      return [selectorSet, outlineSet]
    };

    //load a city and year. returns the result in order BARS, AREAS, POSTCODES, JENKS
    const getData =  (url, postcodeUrl) => {
      return Promise.all([
        GetADataSet(url),
        GetPostcodes(postcodeUrl)
      ]);
    };

    const test = async (data,postcodes) => {
      let features = data.features;
      let jenksSet;
      //find a row that has indices to find the available indices:
      
      
        //console.log(data["jenks"])
        jenksSet = enhanceJenks(data["jenks"]);
        helper.setJenksArr(jenksSet)
        for(let feat of features){
        
          if(Object.keys(feat.properties['indices']).length>0 ){
            //console.log("Populating parameters with: " + Object.keys(feat.properties['indices']))
            console.log(Object.keys(jenksSet["all"]))
            console.log(jenksSet['all']['normalised_transport_accessibility_index'])
            await props.parametersLoadedCallback(Object.keys(jenksSet["all"]))
            break;
          }
        }
        //console.log(jenksSet)
        //helper.setJenksArr(jenksSet)
        //create an outline set
        let areas = helper.deepCopy(data)
        for (let i = 0; i < data.features.length; i++) {
          delete data.features[i]["geom_store"]
          areas.features[i]["geometry"] = areas.features[i]["geom_store"]
          delete areas.features[i]["geom_store"]
          delete areas.features[i]["properties"]["indices"]
        }
        let arr = enhanceDatasets(data, areas, jenksSet["all"], postcodes);
        helper.setData({"bars":arr[0],"areas":arr[1]})
        
        //console.log(currCity.current)
        //console.log(currYear.current)
        setSelectorStructures({
          loaded: true,
          city: props.city,
          year: props.year,
          currUrl: currLoadUrl.current
        });
        setLoading(false);
    }
    const loadnewCity = async (url, postcodes) => {

    
      getData(url, postcodes).then(([data, postcodes]) => {
      
        test(data,postcodes)
      });
    };
    //const myCallbackFunction = props.postcodeCallback;
    if (props.loadUrl !== currLoadUrl.current) {
      setLoading(true);
      currYear.current = props.year;
      currCity.current = props.city;
      currLoadUrl.current = props.loadUrl
      setSelectorStructures({
        loaded: false,
        city: props.city,
        year: props.year,
      });

      loadedPostcodes.current = false;
      loadnewCity(props.loadUrl, props.postcodeUrl);
      //currCity.current = props.city;
    } /*else if (props.year !== currYear.current) {

      setLoading(true);
      currYear.current = props.year;
      loadedPostcodes.current = false;
      currLoadUrl.current = props.loadUrl
      loadnewCity(props.loadUrl, props.postcodeUrl);
    } */
  }, [/*props.city,*/ props.postcodeUrl,/* props.year,*/ props.postcodeCallback, selectorStructures.bars, props.parametersLoadedCallback, props.loadUrl]);

  /**
   *
   * @param {*} url
   * loads the dataset  city and year. 
   */
  async function GetADataSet(url) {
    let fetchstring = process.env.PUBLIC_URL + url;
  
    //console.log(fetchstring)
    return fetch(fetchstring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => response.json());
  }


  //takes the raw jenks data (per year, and "all" over all years) and replaces the index names with the correct ones as per helper.js. sets the currentJenks file to be passed down to the Manager.
  const enhanceJenks = (cityJenks) => {

    let jenksKeys = Object.keys(cityJenks);
    let newJenks = helper.deepCopy(cityJenks);
    let i, j;

    //update Jenks array
    for (i = 0; i < jenksKeys.length; i++) {
      if (jenksKeys[i] === 'city') {
        continue;
      }
      let paramKeys = Object.keys(newJenks[jenksKeys[i]]);

      for (j = 0; j < paramKeys.length; j++) {
        let newKey = helper.getFinalIndexName(paramKeys[j]);
    
        if (newKey !== paramKeys[j]) {
          newJenks[jenksKeys[i]][newKey] = newJenks[jenksKeys[i]][paramKeys[j]];
          delete newJenks[jenksKeys[i]][paramKeys[j]];
        }
        //console.log(newJenks[jenksKeys[i]][paramKeys[j]])
        if(newJenks[jenksKeys[i]][paramKeys[j]] ){

        if(Object.keys(newJenks[jenksKeys[i]][paramKeys[j]]).length===0){
      
          //delete paramater if there is no jenks available. Needs work if we want to display such parameters.
          delete newJenks[jenksKeys[i]][paramKeys[j]];
        }
      }
      }
    }
    return newJenks;
  };

  return (
    <Fragment>
      {isLoading ? <LoadingFeedback /> : ''}
      <DataManager
        /*selectorStructures={{
          bars: selectorStructures.bars,
          areas: selectorStructures.areas,
        }}*/
        structuresLoaded={selectorStructures.loaded}
        //currJenks={selectorStructures.currJenks}
        {...props}
        
          citySync={selectorStructures.city}
        yearSync={selectorStructures.year}
        currUrl = {selectorStructures.currUrl}
      />
    </Fragment>
  );
};

const LoadingFeedback = () => {
  return (
    <div className="loadingPanel">
      <img alt="Loading..." src={spinner} />
    </div>
  );
};
