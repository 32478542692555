import React, {
  Fragment,
  PureComponent,
  //Component,
  useRef,
  useEffect,
  useState,
} from 'react';
import './styles/App.css';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  Slider,
  Tooltip,
  Switch,
  Link,
  FormControlLabel,
  ThemeProvider,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as helper from './Helpers.js';
import { MoreInfo, customTheme } from './CommonComponents.js';
import './styles/Menu.css';
import radiusIcon from './images/radius.svg';
import heightIcon from './images/height.svg';
import Onboarding from './Onboarding';
import { DataLoader } from './DataLoader.js';
import * as Constants from './Constants.js';
import MenuDataDictionary from './MenuDataDictionary';

//radius and height icons
import radius6 from './images/Radius-6.png'
import radius5 from './images/Radius-5.png'
import radius4 from './images/Radius-4.png'
import radius3 from './images/Radius-3.png'
import radius2 from './images/Radius-2.png'
import radius1 from './images/Radius-1.png'

import height6 from './images/Height-6.png'
import height5 from './images/Height-5.png'
import height4 from './images/Height-4.png'
import height3 from './images/Height-3.png'
import height2 from './images/Height-2.png'
import height1 from './images/Height-1.png'

export class UrbanSuperdiversity extends PureComponent {

  m_activeBarColors = [];
  m_activeBarIds = [];
  m_activeAreaColors = [];
  m_currentHover = "";
  usedInitialConfig = false;
  state = {
    subItem: {},
    menuStructure:[],
    city: helper.initialConfig.city,
    year: helper.initialConfig.year,
    tag: helper.initialConfig.tag,
    activeBarRadius: true,
    activeBarHeight: true,
    barColorType: 'multi', // 'linear' or 'multi'
    areaColorType: 'linear', // 'linear' or 'multi'
    barTransparency: 0.8,
    areaTransparency: 0.4,
    indices: helper.initialConfig.indices,
    allIndices: [],
    postcodeUrl: helper.initialConfig.postcodeUrl,
    activeBarColors: [],
    activeAreaColors: [],

    activeHeights: [],
    activeRadii: [],

    barNumColors: 6, // Not used for bivariate
    areaNumColors: 6, // Not used for bivariate
    postcodes: [],
    loadUrl: helper.initialConfig.loadUrl,
    SA3s: [],
    activePostcodes: [],
    activeSA3s: [],
    mapStyle: 'light-v10',
    barsActive: true,
    regionActive: true,
    hoverColourBar: null,
    hoverColourRegion: null,
    hoverRadius: null,
    hoverHeight: null,

    allPostcodes: {},
    selectedBarData: {},
    useJenksArr: [true, true, true, true],
    flexRadius: true,
    showOnboarding: null, // do not change; this value is used for first-start, then it will be true/false

    width: '',
    height: '',
    biv1: '',
    biv2: '',
    rBiv1: '',
    rBiv2: '',
    col: '',
    rCol: '',
  };

  render() {
    return (
      <Fragment>
        {this.state.menuStructure.length === 0 ?  
          <div>Loading...</div>
         :
        <aside id="menu">
          <section className="menu-header">
            <a href="/" title="permalink">
              <h1>
                <span>Urban</span> <span>Superdiversity</span>
              </h1>
            </a>
            <p className="menu-tagline">Mapping cities by demographics</p>
            <h2>Explore</h2>
     
            <nav className="flex-left">
              <div className="dropdown-wrapper">
                <select
                  className="dropdown-large"
                  value={this.state.city}
                  onChange={this.handleActiveCity}
                >
                 
                  {
                  this.state.menuStructure.map((city, i) => (
                    <option key={'city' + i} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.subItem.years ? (
                <div className="dropdown-wrapper">
                  <select
                    className="dropdown-medium"
                    value={this.state.year}
                    onChange={this.handleActiveYear}
                  >
                    {Object.keys(this.state.subItem.years).map((item, i) => (
                      <option key={'year' + i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                
              ) : (
                'Loading...'
              )}
              
            </nav>
            {this.state.subItem.years[this.state.year].tags ? (
            <nav style={{"marginTop":"2px"}}>

                  <div className="dropdown-wrapper">
                    <select
                      className="dropdown-large"
                      value={this.state.tag}
                      onChange={this.handleActiveTag} 
                    >
                      {Object.keys(this.state.subItem.years[this.state.year].tags).map((item, i) => (
                        <option key={'tag' + i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
               
            </nav>
            ) 
            : 
            "Loading"}
            <nav>
              {this.state.hasPostcodeData ? (
                <>
                  <h3>Postcodes</h3>
                  <TempInputElement
                    options={this.state.postcodes}
                    values={this.state.activePostcodes}
                    inputCallback={this.inputCallbackPostcode}
                    placeholder={'Add postcodes...'}
                  />
                </>
              ) : null}
              {this.state.hasSA3Data ? (
                <>
                  <h3>Local Areas</h3>
                  <TempInputElement
                    options={this.state.SA3s}
                    values={this.state.activeSA3s}
                    inputCallback={this.inputCallbackSA3}
                    placeholder={'Add local area names...'}
                  />
                </>
              ) : null}
            </nav>
          </section>

          <ThemeProvider theme={customTheme}>
            <Accordion
              elevation={0}
              defaultExpanded={true}
              className="menu-section"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="visualise-bars"
              >
                <div>
                  <h2>Visualise</h2>
                  <p className="menu-tagline">
                    Select attributes to show on map
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.indices ? (
                  <div style={{ width: '100%' }}>

                    <div className="menu-attribute">
                      <h3>Map colour</h3>
                      <ThemeProvider theme={customTheme}>
                        <RadioGroup
                          row
                          aria-label="area colour"
                          value={this.state.areaColorType}
                          onChange={this.changeAreaColorToggle}
                        >
                          <FormControlLabel
                            control={<Radio color="primary" value="linear" />}
                            label="Linear"
                            name="area-colour"
                          />
                          <FormControlLabel
                            control={<Radio color="primary" value="multi" />}
                            label="Multi"
                            name="area-colour"
                          />
                        </RadioGroup>
                      </ThemeProvider>
                    </div>


                    {this.state.areaColorType === 'linear' ? (
                      <LinearColourSelection
                        options={this.state.indices}
                        type="area"
                        defaultOption={this.state.rCol}
                        callback={this.changeAreaColor}
                        colours={helper.getColors(this.state.areaNumColors)}
                        numColors={this.state.areaNumColors}
                        opacity={this.state.areaTransparency}
                        histogram={this.state.regionLinearHist}
                        mouseInCallback={this.mouseInCallback}
                        mouseOutCallback={this.mouseOutCallback}
                        clickColourCallback={this.clickColourCallback}
                        changeNumColorsCallback={this.changeAreaNumColors}
                        activeAreaSquares={this.state.activeAreaColors}
                      />
                    ) : (
                      <BivariateColourSelection
                        options={this.state.indices}
                        type="area"
                        biv1={this.state.rBiv1}
                        biv2={this.state.rBiv2}
                        histHorizontal={
                          this.state.regionBivariateStackedBarHorizontal
                        }
                        histVertical={
                          this.state.regionBivariateStackedBarVertical
                        }
                        callbackX={this.changeAreaBivariate1}
                        callbackY={this.changeAreaBivariate2}
                        colours={helper.bivariateColors2}
                        opacity={this.state.areaTransparency}
                        mouseInCallback={this.mouseInCallback}
                        mouseOutCallback={this.mouseOutCallback}
                        clickColourCallback={this.clickColourCallback}
                        activeAreaSquares={this.state.activeAreaColors}
                      />
                    )}
                    <div className="menu-attribute">
                      <h3 id="area-opacity-label">Map transparency</h3>
                      <div className="menu-slider">
                        <Slider
                          defaultValue={0.8}
                          value={this.state.areaTransparency}
                          onChange={this.changeAreaTransparency}
                          aria-labelledby="area-opacity-label"
                          valueLabelDisplay="auto"
                          step={0.1}
                          min={0}
                          max={1}
                        />
                      </div>
                    </div>



                    <div className="menu-attribute">
                      <h3>Bar colour</h3>
                      <RadioGroup
                        row
                        aria-label="bar colour"
                        value={this.state.barColorType}
                        onChange={this.changeBarColorToggle}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" value="linear" />}
                          label="Linear"
                          name="bar-colour"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" value="multi" />}
                          label="Multi"
                          name="bar-colour"
                        />
                      </RadioGroup>
                    </div>
                    {
                      (this.state.barColorType === 'linear' ? (
                        <LinearColourSelection
                          options={this.state.indices}
                          type="bar"
                          defaultOption={this.state.color}
                          callback={this.changeColor}
                          colours={helper.getColors2(
                            this.state.barNumColors,
                          )}
                          numColors={this.state.barNumColors}
                          opacity={this.state.barTransparency}
                          histogram={this.state.barLinearHist}
                          mouseInCallback={this.mouseInCallback}
                          mouseOutCallback={this.mouseOutCallback}
                          clickColourCallback={this.clickColourCallback}
                          changeNumColorsCallback={this.changeBarNumColors}
                          activeBarSquares={this.state.activeBarColors}
                        />
                      ) : (
                        <BivariateColourSelection
                          options={this.state.indices}
                          type="bar"
                          biv1={this.state.biv1}
                          biv2={this.state.biv2}
                          histHorizontal={
                            this.state.bivariateStackedBarHorizontal
                          }
                          histVertical={
                            this.state.bivariateStackedBarVertical
                          }
                          callbackX={this.changeBivariate1}
                          callbackY={this.changeBivariate2}
                          colours={helper.bivariateColors}
                          opacity={this.state.barTransparency}
                          mouseInCallback={this.mouseInCallback}
                          mouseOutCallback={this.mouseOutCallback}
                          clickColourCallback={this.clickColourCallback}
                          activeBarSquares={this.state.activeBarColors}
                        />
                      ))}

                    <AttributeSelection
                      options={this.state.indices}
                      title="Bar Height"
                      icon={heightIcon}
                      defaultOption={this.state.height}
                      callback={this.changeHeight}
                      active={this.state.activeBarHeight}
                      toggleCallback={this.toggleHeight}
                      className={
                        this.state.barTransparency > 0
                          ? ''
                          : 'disabledSection'
                      }
                    />
                    <div className='menu-radi-height'>
                      <MenuHoverIcons
                        className="menu-hover-icons-height"
                        activeArray={this.state.activeHeights}
                        clickCallback={this.clickHeightCallback}
                        mouseInFunc={this.mouseInHeightFunc}
                        mouseOutFunc={this.mouseOutHeightFunc}
                        arr={[{ img: height1, size: 1 }, { size: 2, img: height2 }, { size: 3, img: height3 }, { size: 4, img: height4 }, { size: 5, img: height5 }, { size: 6, img: height6 }]}

                      />

                      <div className="hover-line">
                        <span>Low</span>
                        <span className="long-dash"></span>
                        <span>High</span>
                      </div>
                    </div>
                    <AttributeSelection
                      options={this.state.indices}
                      title="Bar Width"
                      icon={radiusIcon}
                      defaultOption={this.state.width}
                      callback={this.changeRadius}
                      active={this.state.activeBarRadius}
                      className={
                        this.state.barTransparency > 0
                          ? ''
                          : 'disabledSection'
                      }
                    />
                    <div className='menu-radi-height'>
                      <MenuHoverIcons
                        className="menu-hover-icons"
                        activeArray={this.state.activeRadii}
                        clickCallback={this.clickRadiusCallback}
                        mouseInFunc={this.mouseInRadiusFunc}
                        mouseOutFunc={this.mouseOutRadiusFunc}
                        arr={[{ img: radius1, size: 1 }, { size: 2, img: radius2 }, { size: 3, img: radius3 }, { size: 4, img: radius4 }, { size: 5, img: radius5 }, { size: 6, img: radius6 }]}

                      />
                      <div className="hover-line">
                        <span>Small</span>
                        <span className="long-dash"></span>
                        <span>Large</span>
                      </div>

                    </div>

                    <div className="menu-attribute">
                      <h3 id="bar-opacity-label">Bar transparency</h3>

                      <div className="menu-slider">
                        <Slider
                          defaultValue={0.7}
                          value={this.state.barTransparency}
                          onChange={this.changeBarTransparency}
                          aria-labelledby="bar-opacity-label"
                          valueLabelDisplay="auto"
                          step={0.1}
                          min={0}
                          max={1}
                        />
                      </div>
                    </div>
                  </div>

                )
                  :
                  <div> Loading... </div>
                }
              </AccordionDetails>
            </Accordion>
          </ThemeProvider>

          <section className="menu-px menu-section">
            <h2>Extras</h2>
            <div className="menu-attribute">
              <h3>Basemap style</h3>
              <div className="dropdown-wrapper">
                <select
                  className="dropdown-small"
                  onChange={this.changeMapStyle}
                >
                  <option value={'light-v10'}>Light</option>
                  <option value={'dark-v10'}>Dark</option>
                  <option value={'streets-v11'}>Streets</option>
                  <option value={'satellite-v9'}>Satellite</option>
                </select>
              </div>
            </div>
            <div className="menu-attribute">
              <ThemeProvider theme={customTheme}>
                <Tooltip
                  arrow
                  className="attribute-tooltip"
                  title={'When enabled, bars will adapt to zoom levels.'}
                >
                  <h3>Dynamic bar sizes</h3>
                </Tooltip>
              </ThemeProvider>

              <FixRadiusToggleElement
                value={this.state.flexRadius}
                clickCallback={this.radiusToggleCallback}
              />
            </div>
          </section>
          <ThemeProvider theme={customTheme}>
            <MenuDataDictionary city={this.state.city} />
          </ThemeProvider>
          <section className="menu-px menu-section menu-about">
            <ThemeProvider theme={customTheme}>
              <h3>About Urban Superdiversity</h3>
              <p>
                Urban Superdiversity is an ongoing multi-institutional collaboration hosted by Monash University with a focus on providing accessible urban data.
              </p>
              <div>
                This tool was created by:
                <ul>
                  <li><b>Co-Chief Investigators:</b> Alan Gamlen (lead), Sarah Goodwin, Xavier Ho, Steven Vertovec, Daniel Hiebert, Paul Spoonley</li>
                  <li><b>Research Assistants, Developers & Key Contributors:</b> Vinay Dixit, Stamen Design, Aateka Shashank, Robert Didham, Ernest Healy, Megan Kelly, Nicholas Spyrison, Toan Nguyen, Katy Morrison, Norbert Winnige, Alexei Matveev, Andreas Hamacher, Nora Hamacher, Mohit Gupta, David Barnes.</li>
                  <li><b>Funding & In-kind Support:</b> Max Planck Society, Australian Research Council, Monash Data Futures Institute.</li>
                </ul>
              </div>
              <Link
                className="block line-height-150"
                href="#"
                onClick={this.handleShowOnboarding}
              >
                Show tutorial
              </Link>
              <Link
                className="block line-height-150"
                href="https://regnet.anu.edu.au/our-people/academic/alan-gamlen"
                target="_blank"
              >
                ANU School of Regulation and Global Governance
              </Link>
              <Link
                className="block line-height-150"
                href="https://superdiv.mmg.mpg.de/"
                target="_blank"
              >
                Global Superdiversity Blog
              </Link>
              <Link
                className="block line-height-150"
                href="https://www.monash.edu/researchinfrastructure/datascienceandai/home"
                target="_blank"
              >
                Monash Data Science and AI Platform
              </Link>
              {/*<Link
                className="block line-height-150"
                href="https://regnet.anu.edu.au/our-people/academic/alan-gamlen"
              >
                Contact us
              </Link>*/}
            </ThemeProvider>
          </section>
        </aside>
            }
        <DataLoader
          bivCallback={this.histogramBivCallback}
          linCallback={this.histogramLinearCallback}
          postcodeCallback={this.postcodeCallback}
          barClickCallback={this.barClickCallback}
          parametersLoadedCallback = {this.parametersLoadedCallback}
          {...this.state}
        />
        <Onboarding show={this.state.showOnboarding} />
      </Fragment>
    );
  }

  //callback function, which gets called from the DataManager when histograms for the bivariate have been called, components holds both the vertical and horizontal component that was created
  //TODO: the same for region, and the linear ones.
  histogramBivCallback = (isRegion, components) => {
    ////console.log(components)
    if (isRegion) {
      this.setState({
        regionBivariateStackedBarVertical: components[1],
        regionBivariateStackedBarHorizontal: components[0],
      });
    } else {
      this.setState({
        bivariateStackedBarVertical: components[1],
        bivariateStackedBarHorizontal: components[0],
      });
    }
  };

  histogramLinearCallback = (isRegion, component) => {
    if (isRegion) {
      this.setState({
        regionLinearHist: component,
      });
    } else {
      this.setState({
        barLinearHist: component,
      });
    }
  };
  mouseOutRadiusFunc = () => {
    //console.log("out " + size);
    this.setState({ hoverRadius: null })
  }

  mouseInRadiusFunc = (size) => {
    this.setState({
      hoverRadius: size - 1,
    });
  };

  mouseInCallback = (col, type) => {
    if (type === "bar") {
      this.setState({
        hoverColourBar: col,
      });
    } else {
      this.setState({
        hoverColourRegion: col,
      });
    }
    this.m_currentHover = col;
    this.forceUpdate()
  }
  mouseOutCallback = (type) => {
    if (type === "bar") {
      this.setState({
        hoverColourBar: null
      })

    } else {
      this.setState({
        hoverColourRegion: null,
      });
    }

    this.forceUpdate()
  }
  mouseOutHeightFunc = () => {
    //console.log("out " + size);
    this.setState({ hoverHeight: null })
  }
  mouseInHeightFunc = (size) => {
    this.setState({
      hoverHeight: size - 1,
    });
  };

  clickHeightCallback = (size) => {

    let index = this.state.activeHeights.indexOf(size - 1);
    //console.log(this.state.activeBarColors);
    let arr = [...this.state.activeHeights]
    if (index < 0) {
      //colour has not been clicked, add to array
      arr.push(size - 1);
    } else {
      //remove this entry
      arr.splice(index, 1);
    }
    //console.log(this.m_activeHeights)
    this.setState({
      activeHeights: arr
    });

  };
  clickRadiusCallback = (size) => {
    //console.log(this.state.activeRadii)
    //console.log(this.m_activeRadii)
    let index = this.state.activeRadii.indexOf(size - 1);
    let arr = [...this.state.activeRadii]
    //console.log(this.state.activeBarColors);
    if (index < 0) {
      //colour has not been clicked, add to array
      arr.push(size - 1);
    } else {
      //remove this entry
      arr.splice(index, 1);
    }
    this.setState({
      activeRadii: arr,
    });

  };

  radiusToggleCallback = () => {
    console.log(!this.state.flexRadius);
    this.setState((prevState) => ({
      flexRadius: !prevState.flexRadius,
    }));
  };

  postcodeCallback = (postcodeYear, SA3year) => {
    //console.log(postcodeYear)
    ////console.log(SA3year)


    if (postcodeYear !== null && postcodeYear && postcodeYear.length > 0) {
      postcodeYear.sort();
      this.setState({
        hasPostcodeData: true,
        postcodes: postcodeYear,
      });
    } else {
      this.setState({
        hasPostcodeData: false,
        postcodes: [],
      });
    }
    if (SA3year !== null && SA3year && SA3year.length > 0) {
      SA3year.sort();
      this.setState({
        hasSA3Data: true,
        SA3s: SA3year,
      });
    } else {
      this.setState({
        hasSA3Data: false,
        SA3s: [],
      });
    }
  };

  barClickCallback = (data) => {
    this.setState({
      selectedBarData: data,
    });
  };

  updateParameter(param, val) {
    //  let arr = [...this.state.paramters];
    //  arr[param] = val;
    //  this.setState({
    //    paraeters: arr,
    //  });
    switch (param) {
      case Constants.WIDTH:
        this.setState({
          width: val,
        });
        break;
      case Constants.HEIGHT:
        this.setState({
          height: val,
        });
        break;
      case Constants.COLOR:
        this.setState({
          col: val,
        });
        break;
      case Constants.BIVARIATE1:
        this.setState({
          biv1: val,
        });
        break;
      case Constants.BIVARIATE2:
        this.setState({
          biv2: val,
        });
        break;
      case Constants.REGIONCOLOR:
        this.setState({
          rCol: val,
        });
        break;
      case Constants.REGIONBIVARIATE1:
        this.setState({
          rBiv1: val,
        });
        break;
      case Constants.REGIONBIVARIATE2:
        this.setState({
          rBiv2: val,
        });
        break;
      default:
        console.log('should not happen.');
    }
  }

  setUpMenu = async() => {
    let datasets = await this.getAvailableDatasetList();
    //console.log(datasets)
    this.buildMenuStructure(datasets);


  }
  getAvailableDatasetList = async () => {
    //to be replaced with a database query
    let fetchstring = process.env.PUBLIC_URL + '/config/availabledatasets.json';
    return fetch(fetchstring, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => response.json());

  }

  componentDidMount() {

    this.setState({
      col: helper.initialConfig.color,
      biv1: helper.initialConfig.biv1,
      biv2: helper.initialConfig.biv2,
      rCol: helper.initialConfig.areacolor,
      rBiv1: helper.initialConfig.biv1,
      rBiv2: helper.initialConfig.biv2,
      width: helper.initialConfig.width,
      height: helper.initialConfig.height,
      tag: helper.initialConfig.tag
    });
    this.setUpMenu();
    this.usedInitialConfig = true;
    //this.setupEvListener();
  }
  
  buildMenuStructure = (datasets) => {
    let struc = []
    for(let i = 0; i < datasets.length; i++){
      if(struc[datasets[i]["city"]]){
        if(!struc[datasets[i]["city"]]["years"][datasets[i]["year"]]){
          struc[datasets[i]["city"]]["years"][datasets[i]["year"]]={}
          //struc[datasets[i]["city"]]["years"].sort((a,b)=>a-b);
          struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"]={}
        }
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"][datasets[i]["tag"]] = {}
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"][datasets[i]["tag"]]["url"] = datasets[i]["url"]
        
          
        
        if(datasets[i]["postcodes"]){
          if(struc[datasets[i]["city"]]["postcodes"]){
            struc[datasets[i]["city"]]["postcodes"][datasets[i]["year"]] = datasets[i]["postcodes"];
          } else {
            struc[datasets[i]["city"]]["postcodes"] = {}
            struc[datasets[i]["city"]]["postcodes"][datasets[i]["year"]] = datasets[i]["postcodes"];
          }
        }
      } else {
        struc[datasets[i]["city"]]={"name" : datasets[i]["city"]};
        struc[datasets[i]["city"]]["years"] ={}
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]={}
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"] = {}
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"][datasets[i]["tag"]]={} 
        struc[datasets[i]["city"]]["years"][datasets[i]["year"]]["tags"][datasets[i]["tag"]]["url"]=datasets[i]["url"] 
        if(datasets[i]["postcodes"]){
          struc[datasets[i]["city"]]["postcodes"] = {}
          struc[datasets[i]["city"]]["postcodes"][datasets[i]["year"]] = datasets[i]["postcodes"];
        }
      }
    }
    //console.log(struc)
    //struc.map(city,i){
      let fin = []
    for(let j = 0; j < Object.keys(struc).length; j++){
      fin.push(struc[Object.keys(struc)[j]])
    }
    //console.log(struc)
  //  console.log(struc[helper.initialConfig.city["postcodes"]])
    //this.m_postcodeUrl =  struc[helper.initialConfig.city["postcodes"]];
    this.setState({
      menuStructure: fin,
      subItem: struc[helper.initialConfig.city]

    });

  }

  changeMapStyle = (event) => {
    //console.log(event.target.value)
    this.setState({
      mapStyle: event.target.value,
    });
  };

  changeBarTransparency = (_, newValue) => {
    this.setState({
      barTransparency: newValue,
      barsActive: newValue !== 0,
    });
    //this.props.callbackF('barTransparency', newValue);
  };
  changeAreaTransparency = (_, newValue) => {
    this.setState({
      areaTransparency: newValue,
      regionActive: newValue !== 0,
    });
  };

  changeRadius = (event) => {
    //console.log(event.target.value + ' radius');
    if (event.target.value === '') {
      this.setState(() => ({
        activeBarRadius: false,
      }));
    } else {
      this.setState(() => ({
        activeBarRadius: true,
      }));
      this.updateParameter(Constants.WIDTH, event.target.value);
    }
  };

  changeHeight = (event) => {
    this.updateParameter(Constants.HEIGHT, event.target.value);
  };

  toggleHeight = (event) => {
    this.setState((prevState) => ({
      activeBarHeight: !prevState.activeBarHeight,
    }));
  };

  changeBarNumColors = (event) => {
    const value = Number(event.target.value);
    this.m_activeBarColors = [];
    this.setState({
      barNumColors: value,
      activeBarColors: [],
    });
  };

  changeAreaNumColors = (event) => {
    const value = Number(event.target.value);
    this.m_activeAreaColors = [];
    this.setState({
      areaNumColors: value,
      activeAreaColors: [],
    });
  };

  changeBarColorToggle = (event) => {
    //console.log(event.target);
    this.setState({
      activeBarColors: [],
    });
    this.m_activeBarColors = [];
    if (event.target.value === 'linear') {
      this.setState(() => ({
        barColorType: 'linear',
      }));
    } else if (event.target.value === 'multi') {
      this.setState(() => ({
        barColorType: 'multi',
      }));
    } else {
      //console.error('Invalid bar color value', event.target.value);
    }

  };

  changeAreaColorToggle = (event) => {
    this.setState({
      activeAreaColors: [],
    });
    this.m_activeAreaColors = [];
    if (event.target.value === 'linear') {
      this.setState(() => ({
        areaColorType: 'linear',
      }));
    } else if (event.target.value === 'multi') {
      this.setState(() => ({
        areaColorType: 'multi',
      }));
    } else {
      //console.error('Invalid area color value', event.target.value);
    }

  };

  changeAreaColor = (event) => {
    this.updateParameter(Constants.REGIONCOLOR, event.target.value);
  };

  changeColor = (event) => {
    this.updateParameter(Constants.COLOR, event.target.value);
  };

  changeBivariate1 = (event) => {
    //console.log('ChangeBivariate1');
    this.updateParameter(Constants.BIVARIATE1, event.target.value);
  };

  changeBivariate2 = (event) => {
    this.updateParameter(Constants.BIVARIATE2, event.target.value);
  };

  changeAreaBivariate1 = (event) => {
    this.updateParameter(Constants.REGIONBIVARIATE1, event.target.value);
  };

  changeAreaBivariate2 = (event) => {
    this.updateParameter(Constants.REGIONBIVARIATE2, event.target.value);
  };

  handleActiveCity = (event) => {
    //console.log(this.state.menuStructure)

    for (let i = 0; i < this.state.menuStructure.length; i++) {
      if (this.state.menuStructure[i].name === event.target.value) {
        let tyear =Object.keys(this.state.menuStructure[i].years)[Object.keys(this.state.menuStructure[i].years).length-1];
        let ttag = Object.keys( this.state.menuStructure[i].years[tyear]["tags"])[0];
        this.setState({
          tag: ttag,
          subItem: this.state.menuStructure[i],
          city: event.target.value,
          year: tyear,
          activePostcodes: [],
          activeSA3s: [],
          loadUrl: this.state.menuStructure[i].years[tyear].tags[ttag].url,
          postcodeUrl: this.state.menuStructure[i].postcodes ?   this.state.menuStructure[i].postcodes[this.state.menuStructure[i].years[
            tyear]] : undefined
          
        });
        break;
      }
    }
  };

  handleActiveTag = (event) => {
    this.setState({
      indices: [],
      allIndices:[]
    })
    const tag = event.target.value;
    this.setState({
      tag:tag,
      activePostcodes: [],
      activeSA3s: [],

     /* indices: helper.getIndicesForCity(this.state.city, year),*/
     loadUrl: this.state.subItem.years[this.state.year]["tags"][tag].url
      //postcodeUrl: this.state.subItem.postcodes ? this.state.subItem.postcodes[year]:  undefined
    });
  };

  handleActiveYear = (event) => {
  
    const year = Number(event.target.value);
    const tag = Object.keys( this.state.subItem.years[year]["tags"])[0]
    this.setState({
      year:year,
      tag: tag,
      activePostcodes: [],
      activeSA3s: [],
     /* indices: helper.getIndicesForCity(this.state.city, year),*/
     loadUrl: this.state.subItem.years[year]["tags"][tag].url,
     //loadUrl: this.state.subItem.urls[year],
      postcodeUrl: this.state.subItem.postcodes ? this.state.subItem.postcodes[year]:  undefined
    });
  };

  clickColourCallback = (colour, type) => {
    if (type === 'bar') {
      let index = this.state.activeBarColors.indexOf(colour);

      if (index < 0) {
        //colour has not been clicked, add to array
        this.m_activeBarColors.push(colour);
        this.m_currentHover = ""
      } else {
        //remove this entry
        this.m_activeBarColors.splice(index, 1);
      }
      //console.log(this.m_activeBarColors)
      this.setState({
        activeBarColors: this.m_activeBarColors,
      });


      this.forceUpdate();
    } else {
      //area click
      let index = this.state.activeAreaColors.indexOf(colour);
      if (index < 0) {
        this.m_activeAreaColors.push(colour);
      } else {
        this.m_activeAreaColors.splice(index, 1);
      }
      //console.log(this.m_activeAreaColors)
      this.setState({
        activeAreaColors: this.m_activeAreaColors,
      });
      this.forceUpdate();
    }
  };

  mouseInFunc = (colour, type) => {
    console.log("mouse In " + colour)
    if (type === 'bar') {
      //check if the colour has already been clicked
      let index = this.state.activeBarColors.indexOf(colour);

      if (index < 0) {
        //colour has not been clicked, add to array
        //console.log(index)
        this.setState({
          hoverColourBar: colour,
        });
        this.forceUpdate();
      }
    } else {
      //area click
      let index = this.state.activeAreaColors.indexOf(colour);
      if (index < 0) {
        this.setState({
          hoverColourRegion: colour,
        });
        this.forceUpdate();
      }
    }
  };

   parametersLoadedCallback = async (vals) => {

    let longNames = []
    for(let i =0; i < vals.length; i++){
      longNames.push(helper.getFinalIndexName(vals[i]))
    }
    longNames.sort();
    helper.setIndices(longNames)

    //this looks messy so here is an explanation.
    /*
    The parameters stay the same as before, if the new dataset also contains the parameter.
    If NOT: the getConfig method from the Helper returns default configs for certain datasets identified by their tag. If the returned value is present in the new dataset, then that is displayed.
    If NOT: A parameter present in the dataset is picked.

    */
    this.setState({
      indices: longNames,
      width:  longNames.indexOf(this.state.width) >= 0 ? this.state.width : longNames.indexOf(helper.getConfig(this.state.tag).width)>=0 ? helper.getConfig(this.state.tag).width : longNames[0],
      height: longNames.indexOf(this.state.height) >= 0 ? this.state.height : longNames.indexOf(helper.getConfig(this.state.tag).height)>=0 ? helper.getConfig(this.state.tag).height : longNames[1],
      biv1: longNames.indexOf(this.state.biv1) >= 0 ?this.state.biv1 : longNames.indexOf(helper.getConfig(this.state.tag).biv1)>=0 ? helper.getConfig(this.state.tag).biv1 : longNames[2],
      biv2: longNames.indexOf(this.state.biv2) >= 0 ? this.state.biv2 :longNames.indexOf(helper.getConfig(this.state.tag).biv2)>=0 ? helper.getConfig(this.state.tag).biv2 : longNames[3],
      rBiv1: longNames.indexOf(this.state.rBiv1) >= 0 ? this.state.rBiv1 :longNames.indexOf(helper.getConfig(this.state.tag).rBiv1)>=0 ? helper.getConfig(this.state.tag).rBiv1 : longNames[4],
      rBiv2: longNames.indexOf(this.state.rBiv2) >= 0 ? this.state.rBiv2 :longNames.indexOf(helper.getConfig(this.state.tag).rBiv2)>=0 ? helper.getConfig(this.state.tag).rBiv2 : longNames[2],
      col: longNames.indexOf(this.state.col) >= 0 ? this.state.col :longNames.indexOf(helper.getConfig(this.state.tag).col)>=0 ? helper.getConfig(this.state.tag).col : longNames[4],
      rCol:longNames.indexOf(this.state.rCol) >= 0 ? this.state.rCol : longNames.indexOf(helper.getConfig(this.state.tag).rCol)>=0 ? helper.getConfig(this.state.tag).rCol : longNames[4],
    })
    this.usedInitialConfig = false;
  }
  inputCallbackPostcode = (values) => {

    this.setState({
      activePostcodes: values,
    });
    //I don't do input control here. At this point, invalid postcodes will result in not displaying anything because the invalid postcode can't be found on the map. It is possible to
    //extract the postcodes and double check that the input is in the list. TODO.
    // this.props.callbackF('postcodeFilter',values.map(str=>str.trim()))
  };
  inputCallbackSA3 = (values) => {
    //let postcodeArray = event.target.value.split(",")   //very crude and user unfriendly, but works for now so I can test.
    this.setState({
      activeSA3s: values,
    });
    //I don't do input control here. At this point, invalid postcodes will result in not displaying anything because the invalid postcode can't be found on the map. It is possible to
    //extract the postcodes and double check that the input is in the list. TODO.
    // this.props.callbackF('SA3Filter',values.map(str=>str.trim()))
  };

  handleShowOnboarding = () => {
    this.setState({ showOnboarding: !this.state.showOnboarding }); // a flick just to trigger the window
  };
}

// Selection dropdown for attributes
const AttributeSelection = ({
  title,
  options,
  defaultOption,
  callback,
  className,
}) => {
  const selectRef = useRef();

  return (
    <section className={`menu-attribute ${className}`}>
      <ThemeProvider theme={customTheme}>
        <Tooltip
          arrow
          className="attribute-tooltip"
          title={helper.getIndexTooltips(selectRef.current?.value || '')}
        >
          <h3>{title}</h3>
        </Tooltip>
      </ThemeProvider>

      <div className="dropdown-wrapper">
        <select
          className="dropdown-large"
          value={defaultOption}
          onChange={(e) => callback(e)}
          ref={selectRef}
        >
          <option value="null">(No mapping)</option>
          {options.map((x, i) => (
            <option key={'option' + i} value={x}>
              {x}
            </option>
          ))}
        </select>
      </div>
    </section>
  );
};

const LinearColourSelection = ({
  type, // 'bar' | 'area'
  options,
  defaultOption,
  callback,
  opacity,
  colours,
  numColors, // 3 or 6
  histogram,
  mouseInCallback,
  mouseOutCallback,
  clickColourCallback,
  changeNumColorsCallback,
  activeAreaSquares,
  activeBarSquares,
}) => {
  const selectRef = useRef();
  const [attribute, setAttribute] = useState('');

  const handleChange = (event) => {
    setAttribute(selectRef.current.value);
    callback(event);
  };

  // Sets the attribute when the DOM has initialised with options
  useEffect(() => {
    setAttribute(selectRef.current.value);
  }, [selectRef, setAttribute]);

  return (
    <div className="menu-color menu-px">
      <div className="linear-histogram" style={{ opacity }}>
        {histogram}
      </div>
      <div className="linear-histogram-legend">
        number of
        <br />
        regions
      </div>
      <ColourSelection
        activeColours={type === 'bar' ? activeBarSquares : activeAreaSquares}
        opacity={opacity}
        colours={colours}
        numColors={numColors}
        type={type}
        clickColourCallback={clickColourCallback}
        mouseInCallback={mouseInCallback}
        mouseOutCallback={mouseOutCallback}
        changeNumColorsCallback={changeNumColorsCallback}
      />
      <div className="linear-attriute-dropdown flex-left">
        <div className="dropdown-wrapper">
          <select
            className="dropdown-medium"
            value={defaultOption}
            onChange={handleChange}
            ref={selectRef}
          >
            {options.map((child, i) => (
              <option key={i} value={child}>
                {child}
              </option>
            ))}
          </select>
        </div>
        <MoreInfo title={helper.getIndexTooltips(attribute)} />
      </div>
    </div>
  );
};

const BivariateColourSelection = ({
  type, // 'bar' | 'area'
  options,
  biv1,
  biv2,
  histHorizontal,
  histVertical,
  callbackX,
  callbackY,
  opacity,
  colours,
  mouseInCallback,
  mouseOutCallback,
  clickColourCallback,
  activeAreaSquares,
  activeBarSquares,
}) => {
  const selectRefX = useRef();
  const selectRefY = useRef();
  const [attributeX, setAttributeX] = useState('');
  const [attributeY, setAttributeY] = useState('');

  const handleChangeX = (event) => {
    setAttributeX(selectRefX.current.value);
    callbackX(event);
  };

  const handleChangeY = (event) => {
    setAttributeY(selectRefY.current.value);
    callbackY(event);
  };

  // Sets the attribute when the DOM has initialised with options
  useEffect(() => {
    //console.log(selectRefX.current.value)
    setAttributeX(selectRefX.current.value);
    setAttributeY(selectRefY.current.value);
  }, [selectRefX, selectRefY, setAttributeX, setAttributeY]);

  return (
    <div className="menu-bivariate-colour menu-px">
      <div className="biv-selection">
        <BivariateSection
          activeColours={type === 'bar' ? activeBarSquares : activeAreaSquares}
          opacity={opacity}
          colours={colours}
          type={type}
          clickColourCallback={clickColourCallback}
          mouseInCallback={mouseInCallback}
          mouseOutCallback={mouseOutCallback}
        />
        <div className="biv-hist-horizontal" style={{ opacity }}>
          {histHorizontal}
        </div>
        <div className="biv-hist-vertical" style={{ opacity }}>
          {histVertical}
        </div>
        <div className="biv-hist-legend">number of regions</div>
        <div className="biv-dropdown-horizontal flex-right">
          <MoreInfo title={helper.getIndexTooltips(attributeX)} />
          <div className="dropdown-wrapper">
            <select
              className="dropdown-medium"
              value={biv1}
              onChange={handleChangeX}
              ref={selectRefX}
            >
              {options.map((x, i) => (
                <option key={'option' + i} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="biv-dropdown-vertical flex-right">
          <div className="dropdown-wrapper">
            <select
              className="dropdown-medium"
              value={biv2}
              onChange={handleChangeY}
              ref={selectRefY}
            >
              {options.map((x, i) => (
                <option key={'option' + i} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
          <MoreInfo
            title={helper.getIndexTooltips(attributeY)}
            className="rotate--90"
          />
        </div>
      </div>
    </div>
  );
};

const BivariateSection = ({
  opacity,
  colours,
  clickColourCallback,
  activeColours,
  type,
  mouseInCallback,
  mouseOutCallback
}) => (
  <>
    {['02', '12', '22', '01', '11', '21', '00', '10', '20'].map((index) => {
      return (
        <BivColourSquare
          colour={colours[index]}
          opacity={opacity}
          clickColourCallback={clickColourCallback}
          mouseInCallback={mouseInCallback}
          mouseOutCallback={mouseOutCallback}
          activeColours={activeColours}
          key={`square-${index}`}
          type={type}
        />
      );
    })}
    <div className="flex-centre biv-line-h">
      <span>Low</span>
      <span className="long-dash"></span>
      <span>High</span>
    </div>
    <div className="flex-centre biv-line-v">
      <span>High</span>
      <span className="long-dash-vertical"></span>
      <span>Low</span>
    </div>
  </>
);

const BivColourSquare = ({
  clickColourCallback,
  colour,
  opacity,
  type,
  activeColours,
  mouseInCallback,
  mouseOutCallback
}) => {
  const onClick = () => {
    clickColourCallback(colour, type);
  };

  const onMouseIn = () => {
    mouseInCallback(colour, type)
  }

  const onMouseOut = (ev) => {
    mouseOutCallback(type)
  }
  return (
    <div
      className={`biv-square ${type + 'square'}  ${activeColours.indexOf(colour) > -1 ? 'colourActive' : ''
        }`}
      onClick={onClick}
      onMouseEnter={onMouseIn}
      onMouseLeave={onMouseOut}
      style={{ backgroundColor: colour, opacity }}
    ></div>
  );
};
const ColourSelection = ({
  type, // 'bar' | 'area'
  opacity,
  colours,
  numColors, // 3 or 6
  clickColourCallback,
  changeNumColorsCallback,
  mouseInCallback,
  mouseOutCallback,
  activeColours,
}) => {
  return (
    <>
      <div className="flex-centre linear-line">
        <span>Low</span>
        <span className="long-dash"></span>
        <span>High</span>
      </div>
      <div className={`color-squares `} style={{ opacity: opacity }}>
        {colours.map((colour, i) => (
          <ColourSquare
            key={i}
            activeColours={activeColours}
            colour={colour}
            numColors={numColors}
            clickColourCallback={clickColourCallback}
            mouseInCallback={mouseInCallback}
            mouseOutCallback={mouseOutCallback}
            type={type}
          />
        ))}
      </div>
      <div className="color-square-number flex-right">
        <div className="dropdown-wrapper">
          <select
            className="dropdown-small"
            value={numColors}
            onChange={changeNumColorsCallback}
          >
            <option key="3" value={3}>
              3 colours
            </option>
            <option key="6" value={6}>
              6 colours
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

const ColourSquare = ({
  clickColourCallback,
  numColors,
  colour,
  type,
  activeColours,
  mouseInCallback,
  mouseOutCallback
}) => {
  const onClick = () => {
    clickColourCallback(colour, type);
  };

  const onMouseIn = () => {
    mouseInCallback(colour, type)
  }

  const onMouseOut = () => {
    mouseOutCallback(type)
  }

  //console.log("ColorSquare")
  return (
    <div
      className={`col-square ${type + 'square'}  ${activeColours.indexOf(colour) > -1 ? 'colourActive' : ''
        } ${'col-square-' + numColors}  `}
      onClick={onClick}
      onMouseEnter={onMouseIn}
      onMouseLeave={onMouseOut}
      style={{ backgroundColor: colour }}
    ></div>
  );
};

const TempInputElement = ({ options, values, inputCallback, placeholder }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={options}
        value={values}
        values={values}
        onChange={(event, values) => inputCallback(values)}
        // getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            margin="none"
            placeholder={placeholder}
          />
        )}
      />
    </ThemeProvider>
  );
};

const FixRadiusToggleElement = ({ value, clickCallback }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Switch
        checked={value}
        size="small"
        color="primary"
        onChange={clickCallback}
        name={'Fix radius'}
        label="Fix radius at current value"
      // getOptionLabel={(option) => option.title}
      />
    </ThemeProvider>
  );
};

const MenuHoverIcons = ({ className,
  radius,
  activeArray,
  clickCallback,
  mouseInFunc,
  mouseOutFunc,
  arr }) => {
  return (

    <div className={className} style={{ width: '100%', height: "25px" }}>

      {arr.map((el) => (
        <div className={`menu-hover-icon-container-${radius ? "radius" : "height"}  ${activeArray.indexOf(el.size - 1) > -1 ? 'iconActive' : ''}`}
          key={"hover-"+radius+"-"+el.size}
          onClick={() => clickCallback(el.size)}
          onMouseLeave={() => mouseOutFunc()}
          onMouseEnter={() => mouseInFunc(el.size)}  >
          <img className="hover-icon" src={el.img} alt={el.size} />

        </div>
      ))}


    </div>

  );
};