export const COLOR = 0;
export const BIVARIATE1 = 1;
export const BIVARIATE2 = 2;
export const REGIONCOLOR = 3;
export const REGIONBIVARIATE1 = 4;
export const REGIONBIVARIATE2 = 5;
export const HEIGHT = 6;
export const WIDTH = 7;

export const BARS = 0;
export const AREA = 1;

export const JENKS_WIDTH = 0;
export const JENKS_HEIGHT = 1;
export const JENKS_BARS = 2;
export const JENKS_REGION = 3;

export const PARAMETERNAMES = ["color","biv0","biv1","areaColor","rBiv0","rBiv1","height","width"]