import React, { useEffect, useRef, useState, useCallback } from 'react';
import { select, scaleBand, stack, max, scaleLinear } from 'd3';
import useResizeObserver from './useResizeObserver';

/**
 * Component that renders a StackedBarChart
 */

const StackedHistogram = ({
  data,
  keys,
  colors,
  hoverCallback,
  isRegion,
  annotationValues = [],
  width = 120,
  height = 40,
  linear = false,
}) => {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [highlightKey, setHighlightKey] = useState(null);

  const layerOverHandler = useCallback(
    function (d, i) {
      if (hoverCallback) {
        hoverCallback(i['key'], isRegion);
        setHighlightKey(i['key']);
      }
    },
    [hoverCallback, setHighlightKey, isRegion],
  );

  const layerLeaveHandler = useCallback(
    function () {
      if (hoverCallback) {
        hoverCallback(null, isRegion);
        setHighlightKey(null);
      }
    },
    [hoverCallback, setHighlightKey, isRegion],
  );

  const histogramOverHandler = useCallback(
    function () {
      if (hoverCallback) {
        select(this).raise();
      }
    },
    [hoverCallback],
  );

  const histogramLeaveHandler = useCallback(
    function () {
      if (hoverCallback) {
        select(this).lower();
      }
    },
    [hoverCallback],
  );

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();

    // stacks / layers
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1])),
    ];

    // scales
    // If there are annotations, don't draw to the maximum range
    const xScale = scaleBand()
      .domain(data.map((d) => d.bin))
      .range(annotationValues.length > 0 ? [6, width - 6] : [0, width]);

    const yScale = scaleLinear()
      .domain(extent)
      .range([height, annotationValues.length > 0 ? 20 : 0]);

    const xScaleAnnotation = scaleLinear()
      .domain([0, 1])
      .range([6, width - 6]);
    const yScaleAnnotation = scaleLinear().domain(extent).range([height, 6]);

    // Only reset filter when mouse is out of SVG
    svg.on('mouseleave', layerLeaveHandler);

    // rendering
    const g = svg
      .selectAll('.layer')
      .data(layers)
      .join('g')
      .attr('class', 'layer');

    g.attr('fill', (d) => (linear ? d.key : colors[d.key]))
      .attr('stroke', (d) =>
        highlightKey === d.key ? 'lightcoral' : 'transparent',
      )
      .on('mouseover', layerOverHandler)
      .selectAll('rect')
      .attr('class', 'historgram-rect')
      .data((layer) => layer)
      .join('rect')
      .on('mouseover', histogramOverHandler)
      .on('mouseleave', histogramLeaveHandler)
      .attr('x', (sequence) => xScale(sequence.data.bin))
      .attr('width', xScale.bandwidth())
      .attr('y', (sequence) => yScale(sequence[1]))
      .attr(
        'height',
        (sequence) => yScale(sequence[0]) - yScale(sequence[1]) || 0,
      )
      .attr('stroke', null)
      .attr('stroke-width', '2');

    const annotation = svg.selectAll('.annotation').data(annotationValues);

    const annotationEnter = annotation
      .enter()
      .append('g')
      .attr('class', 'annotation');

    annotationEnter
      .append('line')
      .attr('stroke', 'rgb(37, 99, 162)')
      .attr('stroke-width', 2)
      .attr('y1', yScaleAnnotation(extent[0]))
      .attr('y2', yScaleAnnotation(extent[1]))
      .attr('x1', (d) => xScaleAnnotation(d))
      .attr('x2', (d) => xScaleAnnotation(d));

    annotationEnter
      .append('circle')
      .attr('fill', 'rgb(37, 99, 162)')
      .attr('r', 6)
      .attr('cy', yScaleAnnotation(extent[1]))
      .attr('cx', (d) => xScaleAnnotation(d));

    annotation.exit().remove();
  }, [
    colors,
    data,
    dimensions,
    keys,
    linear,
    isRegion,
    hoverCallback,
    highlightKey,
    setHighlightKey,
    annotationValues,
    histogramOverHandler,
    histogramLeaveHandler,
    layerLeaveHandler,
    layerOverHandler,
  ]);

  return (
    <div style={{ width, height }} ref={wrapperRef}>
      <svg ref={svgRef} x="0" y="0" width={width} height={height}></svg>
    </div>
  );
};

export default StackedHistogram;
