import React from 'react';
import { Tooltip, ThemeProvider, createMuiTheme } from '@material-ui/core';

export const MoreInfo = ({ title, className = '', children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Tooltip arrow className={`info-tooltip ${className}`} title={title}>
        <aside>i</aside>
      </Tooltip>
    </ThemeProvider>
  );
};

// As it turns out. Material UI tooltips are very difficult to style
// This method works with the theme provider.
// See https://material-ui.com/customization/globals/#css
export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(37, 99, 162)', // ui-blue-400
    },
    secondary: {
      main: 'rgb(206, 240, 242)', // ui-blue-200
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        color: '#052e57', // ui-blue-800
        backgroundColor: 'rgba(239, 249, 250, 0.9)', // ui-blue-000
        padding: '0 2em',
        marginTop: '0 !important',
        top: '0px',
        borderBottom: '1px solid rgb(206, 240, 242)',
        '&::before': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 !important', // accordion summary and details inherits the padding from above, lol
      },
      // unfortunately a lot of the "expanded" states are not overridable so we do it like this
      content: {
        margin: '8px 0 !important',
      },
    },
    MuiAccordionDetails: {
      root: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingBottom: '0',
        margin: '0 !important',
        fontSize: '0.8em',
      },
    },
    MuiButton: {
      root: {
        color: '#052e57', // ui-blue-800
        fontFamily: "'Inter var', 'Inter', sans-serif",
        fontWeight: 700,
      },
      contained: {
        backgroundColor: 'rgb(206, 240, 242)', // ui-blue-200
        color: '#052e57', // ui-blue-800
        fontFamily: "'Inter var', 'Inter', sans-serif",
        fontWeight: 700,
      },
      containedSecondary: {
        color: '#052e57', // ui-blue-800
        '&:hover': {
          backgroundColor: 'rgb(206, 240, 242)',
        },
      },
    },
    MuiChip: {
      root: {
        color: 'rgb(37, 99, 162)', // ui-blue-400
        backgroundColor: 'rgb(206, 240, 242)', // ui-blue-200
      },
      deleteIcon: {
        color: 'rgba(125, 181, 237)', // ui-blue-300
      },
    },
    MuiCollapse: {
      entered: {
        paddingBottom: '1rem',
      },
    },
    MuiDrawer: {
      // side menu
      paper: {
        top: '180px',
        height: 'calc(100% - 180px)',
        boxShadow: '2px 0 2px 0 rgba(0, 0, 0, 0.2)', // ui-gray-shadow
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'nowrap',
      },
    },
    MuiLink: {
      root: {
        fontFamily: "'Inter var', 'Inter', sans-serif",
        fontSize: '0.9rem',
      },
    },
    MuiPaper: {
      // side menu contents
      root: {
        color: '#052e57 !important', // ui-blue-800
        backgroundColor: 'rgba(239, 249, 250) !important', // ui-blue-000
      },
    },
    MuiSvgIcon: {
      root: {
        color: 'rgb(37, 99, 162)', // ui-blue-400
      },
    },
    MuiSwitch: {
      root: {
        color: 'rgb(37, 99, 162)', // ui-blue-400
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem', // The default font size is way too small
      },
    },
    MuiTypography: {
      root: {
        fontFamily: "'Inter var', 'Inter', sans-serif",
      },
      body1: {
        fontFamily: "'Inter var', 'Inter', sans-serif",
        fontSize: '0.9rem',
        letterSpacing: 'initial',
      },
    },
  },
});
