import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Fade,
  Backdrop,
  Paper,
  ThemeProvider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { customTheme } from './CommonComponents.js';
import Onboarding1 from './images/onboarding_bivariate.jpg';
import Onboarding2 from './images/onboarding_filter.jpg';
import Onboarding3 from './images/onboarding_popup.jpg';

const Onboarding = ({ show }) => {
  const [onboarding, setOnboarding] = useState(1);
  const supportsLocalStorage = storageAvailable('localStorage');
  const [showOnboardingAtStart, setShowOnboardingAtStart] = useState(
    supportsLocalStorage
      ? localStorage.getItem('showOnboardingAtStart') !== 'false' // localStorage only stores strings
      : true,
  );
  const [open, setOpen] = useState(true && showOnboardingAtStart);

  useEffect(() => {
    if (show !== null) {
      setOnboarding(1);
      setOpen(true);
    }
  }, [show]);

  const onboardingTitles = {
    1: 'Discover the urban superdiversity map',
    2: 'Explore four different cities',
    3: 'Drill in for more detail',
  };

  const handleNextClick = useCallback(() => {
    if (onboarding < 3) {
      setOnboarding(onboarding + 1);
    } else {
      setOpen(false);
    }
  }, [onboarding, setOnboarding, setOpen]);

  const handleBackClick = useCallback(() => {
    if (onboarding > 1) {
      setOnboarding(onboarding - 1);
    }
  }, [onboarding, setOnboarding]);

  const handleShowOnboardingPreference = useCallback(
    (checked) => {
      setShowOnboardingAtStart(checked);
      localStorage.setItem('showOnboardingAtStart', checked);
    },
    [setShowOnboardingAtStart],
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Modal
        open={open}
        aria-labelledby="onboarding-title"
        className="onboarding-backdrop"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={16} className="onboarding">
            <h2 id="onboarding-title">{onboardingTitles[onboarding]}</h2>
            <div className="onboarding-slideshow">
              <img
                src={Onboarding1}
                alt="Choose up to 2 diversity data to visualise"
                className={onboarding === 1 ? 'visible' : 'hidden'}
              />
              <img
                src={Onboarding2}
                alt="Filter the map vis postcodes or area names"
                className={onboarding === 2 ? 'visible' : 'hidden'}
              />
              <img
                src={Onboarding3}
                alt="Double click on a bar to see more details"
                className={onboarding === 3 ? 'visible' : 'hidden'}
              />
            </div>
            <div className="onboarding-progressbar">
              <div
                className={`onboarding-progress ${
                  onboarding === 1 ? 'active' : ''
                }`}
              ></div>
              <div
                className={`onboarding-progress ${
                  onboarding === 2 ? 'active' : ''
                }`}
              ></div>
              <div
                className={`onboarding-progress ${
                  onboarding === 3 ? 'active' : ''
                }`}
              ></div>
            </div>
            <div className="onboarding-actions">
              <FormControlLabel
                visible={supportsLocalStorage}
                control={
                  <Checkbox
                    checked={showOnboardingAtStart}
                    onChange={(e) =>
                      handleShowOnboardingPreference(e.target.checked)
                    }
                    name="show-onboarding"
                  />
                }
                label="Show tutorial at start"
              />
              <div className="onboarding-buttons">
                <Button
                  color="primary"
                  className={onboarding === 1 ? 'hidden' : 'visible'}
                  onClick={handleBackClick}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNextClick}
                >
                  {onboarding === 3 ? "Let's Go" : 'Next'}
                </Button>
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

// Feature detection from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export default Onboarding;
