import { treemap } from 'd3-hierarchy';
import mapboxgl from 'mapbox-gl';
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as helper from './Helpers';
import MapNavigation from './MapNavigation';

//import geojson from 'geojson'
export const MapFunctionsHook = (props) => {
  // set to 2017 initially despite play preview or you get a bug when using the type dropdown
  const initiated = useRef(false)
  const map = useRef()
  const tooltipContainer = useRef()
  const clickedMarkerDiv = useRef();
  //const m_tilesets= useRef();
  //const onlyOnce= useRef();
  const hoveredBarId = useRef();
  const pitch = useRef(45);
  const bearing = useRef(-17.6);
  const zoom = useRef(10);
  const loc = useRef(null);
  const popupfeatures = useRef(null);
  const dataAvailLocal = useRef(props.dataAvail)

  const colourFilter = useRef(props.colourFilter)
  const regionColourFilter = useRef(props.regionColourFilter)
  const postcodeFilter = useRef(props.postcodeFilter)
  const SA3Filter = useRef(props.SA3Filter)
  const heightFilter = useRef(props.heightFilter)
  const radiusFilter = useRef(props.radiusFilter)

  const city = useRef(props.city)
  const year = useRef(props.year)
  const mapStyle = useRef(props.mapStyle)
  useEffect(() => {
    tooltipContainer.current = document.createElement('div');
    clickedMarkerDiv.current = document.createElement('div');
  }, []); //only once on startup

  useEffect(() => {
    dataAvailLocal.current = props.dataAvail;
    const setTooltip = (show, data) => {
      if (show) {
        ReactDOM.render(
          React.createElement(PopupContent, {
            data,
            openNeighbourhoodCallback: props.openNeighbourhoodCallback,
            closePopupCallback: closePopup,
          }),
          tooltipContainer.current,
        );
        ReactDOM.render(React.createElement(ClickedMarker), clickedMarkerDiv.current);
      } else {
        ReactDOM.unmountComponentAtNode(tooltipContainer.current);
        ReactDOM.unmountComponentAtNode(clickedMarkerDiv.current);
      }
    }
    const drawPopup = (show) => {
      setTooltip(show, popupfeatures.current);
      if (typeof props.neighbourhoodCallback === 'function') {
        //console.log(this.popupfeatures)
        props.neighbourhoodCallback(popupfeatures.current);
      }
    }
    const closePopup = () => {
      drawPopup(false);
      //this.m_showpopup = false;
      props.barClickCallback();
      if (typeof props.neighbourhoodCallback === 'function') {
        props.neighbourhoodCallback(null);
      }
      props.closePopupCallback();
    };

    
    const init = () => {
      hoveredBarId.current = null;

      if (!initiated.current) {
        console.log('map init');

        initiated.current = true;
        switch (props.city) {
          case 'Vancouver':
            if (loc.current === null) loc.current = [-123.1284369, 49.2744749];

            break;
          case 'Melbourne':
            if (loc.current === null) loc.current = [144.96, -37.84];
            break;
          case 'Auckland':
            if (loc.current === null) loc.current = [174.76, -36.84];
            break;
          case 'Sydney':
            if (loc.current === null) loc.current = [151.21, -33.87];
            break;
          case 'Perth':
            if (loc.current === null) loc.current = [115.86, -31.95];
            break;
          case 'Adelaide':
            if (loc.current === null) loc.current = [138.60, -34.92];
            break;
          case 'Brisbane':
            if (loc.current === null) loc.current = [153.02, -27.47];
            break;
          default:
            console.log('Error in map init city: ' + props.city);
        }

        mapboxgl.accessToken =
          'pk.eyJ1Ijoibm9yYWhhbWEiLCJhIjoiY2ptaGFsZDR5MThrczN1dDhtajc1cTFmMSJ9.VEUImGmfsM77LfjErYxDdQ';
        //mapboxgl.accessToken ="pk.eyJ1Ijoic2dlb3ZpeiIsImEiOiJnZ1VQQ1ZNIn0.kX6gvvUIGV9VGpiixzGtPg"
        map.current = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/' + props.mapStyle,
          zoom: [zoom.current],
          center: loc.current,
          pitch: pitch.current, //45,
          bearing: bearing.current, //-17.6,
        });

        map.current.on('load', () => {
          //console.log('map.load');
          // console.log(props.data)
          //console.log(props.regiondata)
          // this.onlyOnce = false;
          //this.helperIndex = 0;

          map.current.addSource('centerData', {
            type: 'geojson',
            data: helper.getDatas()["bars"],
            maxzoom: 12,
          });

          map.current.addSource('outlineData', {
            type: 'geojson',
            data: helper.getDatas()["areas"],
          });
          //let self = this;

          map.current.addControl(new mapboxgl.NavigationControl(true, true, true));

          map.current.on('zoom', function () {
            // console.log(self.map.getZoom())
            //< 11 stop 0
            // > 11 step 1
            // > 12 step2
            props.zoomCallback(map.current.getZoom());
          });

          let visReg = 'none';
          let visBar = 'none';
          if (props.showRegions) {
            visReg = 'visible';
          }
          if (props.showBars) {
            visBar = 'visible';
          }
          //        console.log("fill"+this.m_tilesets[i].year)
          map.current.addLayer({
            id: 'fillRegions',
            type: 'fill',
            source: 'outlineData',
            layout: {
              // make layer visible by default
              visibility: visReg,
            },
            paint: {
              'fill-color': ['get', 'areaColor'],
              'fill-outline-color': 'black',
              'fill-opacity': props.areaTransparency,
            },
          });

          map.current.addLayer({
            id: 'lineLayer',
            type: 'line',
            source: 'outlineData',
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-color': '#b4b8b5',
              'line-width': 1,
            },
          });

          map.current.addLayer({
            id: 'barLayer',
            type: 'fill-extrusion',
            source: 'centerData',
            layout: {
              // make layer visible by default
              visibility: visBar,
            },
            // 'source-layer': this.m_tilesets[ props.year].sourcelayer,
            paint: {
              'fill-extrusion-color': ['get', 'color'],
              'fill-extrusion-height': ['get', 'height'],
              'fill-extrusion-opacity': props.barTransparency,
            },
          });

          const clickedMarker = new mapboxgl.Marker(clickedMarkerDiv.current, {
            offset: [0, 0],
          })
            .setLngLat([0, 0])
            .addTo(map.current);
          const tooltip = new mapboxgl.Marker(tooltipContainer.current, {
            offset: [160, 0], // this number should be matched by how wide the tooltip is
          })
            .setLngLat([0, 0])
            .addTo(map.current);

          /*  map.current.on('mouseenter', 'barLayer', function (e) {
            self.map.getCanvas().style.cursor = 'pointer';
          });
  
          map.current.on('mouseleave', 'barLayer', function (e) {
            self.map.getCanvas().style.cursor = '';
          });  
          //removed, this does almost nothing and slows down considerably
  */
          map.current.on('dblclick', 'barLayer', function (e) {
            // Pack statistical properties to value and classes in one structure
            let uid = helper.getUIDForCityYear(props.city, props.year);
            popupfeatures.current = {
              // display the uid column as well if it exists
              uid: uid
                ? `${uid}: ${e.features[0].properties[
                helper.getUIDForCityYear(props.city, props.year)
                ]
                }`
                : '',
              areaid:
                e.features[0].properties[
                helper.getAreaIDForCityYear(props.city, props.year)
                ],
              properties: [],
            };
            // console.log('double click', self.popupfeatures, e.features[0]);
            const indices = JSON.parse(e.features[0].properties['indices']);
            const indexClasses = JSON.parse(
              e.features[0].properties['indexClasses'],
            );


            /*  const popupAllowList = [
                 self.props.height,
                 self.props.width,
    
              ];
              if( self.props.barColorType==="multi"){
                popupAllowList.push( self.props.biv1)
                popupAllowList.push( self.props.biv2)
              } else {
                popupAllowList.push( self.props.col)
              }
              if( self.props.areaColorType==="multi"){
                popupAllowList.push( self.props.rBiv1)
                popupAllowList.push( self.props.rBiv2)
              } else {
                popupAllowList.push( self.props.rCol)
              }*/
            Object.keys(indices).forEach((k) => {
              if (indices[k] === -1) return; // -1 means data do not exist
              //if (popupAllowList.includes(k)) {
              popupfeatures.current.properties.push({
                index: k,
                value: indices[k],
                indexClass: indexClasses[k],
              });
              //  }
            });

            props.barClickCallback(e.features[0].properties);
            clickedMarker.setLngLat(e.lngLat);
            tooltip.setLngLat(e.lngLat);
            drawPopup(false);

            map.current.getCanvas().style.cursor = 'pointer';
            drawPopup(true);

            // e.stopPropagation()
          });
          map.current.setFilter('barLayer', props.colourFilter);
          map.current.setFilter('fillRegions', props.regionColourFilter);
          map.current.doubleClickZoom.disable();
          //} //for tileset end
        });
        // Change the cursor style as a UI indicator.
        map.current.getCanvas().style.cursor = 'pointer';
      }
    }

    if (props.city !== city.current) {
      city.current = props.city
      loc.current = null;
      bearing.current = -17.6;
      pitch.current = 45;
      zoom.current = 10;
      initiated.current = false;
    }

    if (props.mapStyle !== mapStyle.current) {
      mapStyle.current = props.mapStyle
      bearing.current = map.current.getBearing();
      pitch.current = map.current.getPitch();
      loc.current = map.current.getCenter();
      zoom.current = map.current.getZoom();
      map.current = null;
      initiated.current = false;
    }
    if (dataAvailLocal.current) {

      init();

    }

    if (props.year !== year.current && props.city === city.current) {
      
      year.current = props.year;
      if (map.current) {
      map.current.getSource('centerData').setData(helper.getDatas()["bars"]);

      map.current.getSource('outlineData').setData(helper.getDatas()["areas"]);
      //    map.current.setFilter('barLayer', props.colourFilter)
      //    map.current.setFilter('fillRegions', props.regionColourFilter)
      closePopup();
      }
    }
    if (map.current && map.current.getSource('centerData')) {
      map.current.getSource('centerData').setData(helper.getDatas()["bars"]);
      map.current.getSource('outlineData').setData(helper.getDatas()["areas"]);
    }
  }, [props.dataAvail, props.updateData, props.city, props.year, props.mapStyle]);


  useEffect(() => {
    //console.log(map.current)
    if (map.current) {
      if (props.showBars) {
        map.current.setLayoutProperty('barLayer', 'visibility', 'visible');
      } else {
        map.current.setLayoutProperty('barLayer', 'visibility', 'none');
      }
    }
  }, [props.showBars])
  /*componentDidMount() {
    //console.log('MapFunctions mount');
    this.tooltipContainer = document.createElement('div');
    this.clickedMarker = document.createElement('div');
  }*/
  useEffect(() => {
    if (map.current) {
    if (props.showRegions) {
      map.current.setLayoutProperty('fillRegions', 'visibility', 'visible');
      map.current.setLayoutProperty('lineLayer', 'visibility', 'none');
    } else {
      map.current.setLayoutProperty('fillRegions', 'visibility', 'none');
      map.current.setLayoutProperty('lineLayer', 'visibility', 'visible');
    }
  }
  }, [props.showRegions])

  useEffect(() => {
    if (map.current) {
    map.current.setPaintProperty(
      'barLayer',
      'fill-extrusion-opacity',
      props.barTransparency,
    );
    }
  }, [props.barTransparency])

  useEffect(() => {
    if (map.current) {
    map.current.setPaintProperty(
      'fillRegions',
      'fill-opacity',
      props.areaTransparency,
    );
    }
  }, [props.areaTransparency])


  useEffect(() => {
    
    const setFilters = (bars, regions) => {
      let nullFilter = ['!=', ['get', 'dummy'], 'pink'];

      let postcodeFilter =
        props.postcodeFilter === null
          ? nullFilter
          : props.postcodeFilter;
      let SA3Filter =
        props.SA3Filter === null ? nullFilter : props.SA3Filter;
      let colourFilter =
        props.colourFilter === null ? nullFilter : props.colourFilter;
      let regionColourFilter =
        props.regionColourFilter === null
          ? nullFilter
          : props.regionColourFilter;
      let heightFilter =
        props.heightFilter === null ? nullFilter : props.heightFilter;
      let radiusFilter =
        props.radiusFilter === null ? nullFilter : props.radiusFilter;
      let SA3PostCodeFilter;
      if (SA3Filter === nullFilter && postcodeFilter !== nullFilter) {
        SA3PostCodeFilter = postcodeFilter;
      } else if (SA3Filter !== nullFilter && postcodeFilter === nullFilter) {
        SA3PostCodeFilter = SA3Filter;
      } else if (SA3Filter !== nullFilter && postcodeFilter !== nullFilter) {
        SA3PostCodeFilter = ['any', SA3Filter, postcodeFilter];
      } else {
        SA3PostCodeFilter = nullFilter;
      }
      //console.log(SA3PostCodeFilter)
      //console.log(radiusFilter)
      if (map.current) {
        if (bars) {
          let barFilter = ['all', SA3PostCodeFilter, colourFilter, heightFilter, radiusFilter];
          //console.log(props.data)
          map.current.setFilter('barLayer', barFilter);
        }
        if (regions) {
          let regionFilter = ['all', SA3PostCodeFilter, regionColourFilter];
          //console.log(props.regiondata)
          map.current.setFilter('fillRegions', regionFilter);
        }
      }
    }
    if (map.current) {
    if (props.colourFilter !== colourFilter.current) {
      colourFilter.current = props.colourFilter
      setFilters(true, false);
    } else if (props.regionColourFilter !== regionColourFilter.current) {
      regionColourFilter.current = props.regionColourFilter
      setFilters(false, true);
    } else if (props.postcodeFilter !== postcodeFilter.current) {
      postcodeFilter.current  = props.postcodeFilter
      setFilters(true, true);
    } else if (props.SA3Filter !== SA3Filter.current) {
      SA3Filter.current  = props.SA3Filter
      setFilters(true, true);
    } else if (props.heightFilter !== heightFilter.current) {
      heightFilter.current  = props.heightFilter
      setFilters(true, false);
    } else if (props.radiusFilter !== radiusFilter.current) {
      radiusFilter.current  = props.radiusFilter
      setFilters(true, false);
    }
  }

  }, [props.colourFilter, props.regionColourFilter, props.postcodeFilter, props.SA3Filter, props.heightFilter, props.radiusFilter])



  return (
    <div className="mapContainer">
      <div id="map" />
      <MapNavigation />
    </div>
  );
}


const PopupContent = ({
  data,
  closePopupCallback,
  openNeighbourhoodCallback,
}) => (
  <div className="popup">
    <div>
      <div className="popup-heading">
        <p className="popup-areaid">{data.areaid}</p>
        <p className="popup-uid">{data.uid}</p>
      </div>
      {data.properties.map(({ index, value, indexClass }, i) =>
        index.indexOf('_') < 0 && index !== 'year' ? ( //NH: This is very quick and dirty to prevent the new liveability parameters to clutter the tooltip. They still appear in the neighbourhood characteristics and can be
          //scrolled to ,  I'm not sure if that is good or bad.
          <p key={i} className="popup-features">
            <span>
              <strong>{index}</strong>:
            </span>
            <span>
              {helper.getIndexText(indexClass)} (
              {helper.renderNumber(index, value)})
            </span>
          </p>
        ) : (
          ''
        ),
      )}
    </div>
    <div className="popup-actions">
      <button className="popup-see-details" onClick={openNeighbourhoodCallback}>
        See Details
      </button>
      <button className="popup-close" onClick={closePopupCallback}>
        Close
      </button>
    </div>
  </div>
);

const ClickedMarker = () => <div className="clicked-marker"></div>;
